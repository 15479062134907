import camelCase from 'lodash/camelCase';
import PaymentPageFeaturesModel from './PaymentPageFeaturesModel';
import {ViaEnum} from '@core/types/graphql';
import {getClientInstance} from '@core/graphql/client';
import invalidateCacheByTypename from '@core/graphql/utils/invalidateCacheByTypename';

/**
 * @class PaymentPageMicroFeaturesModel
 * @see PaymentPageFeaturesModel
 * @see PaymentPageModel
 */
const PaymentPageMicroFeaturesModel = PaymentPageFeaturesModel.extend({
    urlRoot: '/pay/microFeatures/isJsonMode/true',
    actionId: 'microFeatures',

    initialize() {
        PaymentPageFeaturesModel.__super__.initialize.call(this);
        this.listenTo(this, 'change:via', this.__onChangeVia);
    },

    /**
     * @returns {string}
     * @public
     */
    getSelectedFeatureName: function () {
        var selectedPackage = this.getSelectedPackage();

        if (!selectedPackage) {
            return this.get('prevVia');
        }

        return camelCase(selectedPackage.get('package').title);
    },

    /**
     * @override
     * @returns {string}
     */
    getMicrofeature: function () {
        return this.isDeclinedPay() ? this.get('prevVia') : this.get('via');
    },

    /**
     * @private
     */
    __onChangeVia() {
        if (this.get('via') === ViaEnum.inpage_decline) {
            const client = getClientInstance();
            /**
             * To update payment banner in {@see NotificationPopup}.
             */
            invalidateCacheByTypename(client, 'UserFeatures', 'paymentLastOrder');
            /** Invalidate setting of photo motivation to prevent show microfeature motivation */
            invalidateCacheByTypename(
              client,
              'UserFeatures',
              'photoUploadMotivationSettings'
            );
        }
    }
});
export default PaymentPageMicroFeaturesModel;
