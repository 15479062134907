import {ViaEnum} from '@core/types/graphql';

/**
 * Get the first payment scene from via list that past to the search conditions
 * @return {string|null}
 */
function getCorrectPaymentScene() {
    let via = this.get('via');
    const viaList = this.get('vias');

    if (this.isDeclinedPay()) {
        via = this.get('prevVia') || via;
    }

    for ( const key in viaList) {
        if (viaList[key]?.includes(via) || key === via) {
            return (key === ViaEnum.generous_gift) && this.isDefaultCardPaymentType() ? ViaEnum.account : key;
        }
    }

    return null;
}

export default getCorrectPaymentScene;
