import getCountryISO2 from 'country-iso-3-to-2';
import IBAN from 'iban';
import { htmlStringToElements } from 'components/application/helpers';
import LoadOnDemandBehavior from 'components/application/LoadOnDemandBehavior';
import PaymentPageUserModel from 'components/paymentPage/models/PaymentPageUserModel';
import keys from 'lodash/keys';
import includes from 'lodash/includes';
import pick from 'lodash/pick';
import some from 'lodash/some';
import find from 'lodash/find';
import get from 'lodash/get';
import without from 'lodash/without';
import result from 'lodash/result';
import PaymentPagePackageCollection from 'components/paymentPagePackage/models/PaymentPagePackageCollection';
import updatePaymentPageVisitsInCache from 'components/paymentPage/utils/updatePaymentPageVisitsInCache';
import PaymentPagePayModelFactory from 'app/components/paymentPage/PaymentPagePayModelFactory';
import PaymentPageDirectiveManager from '../PaymentPageDirectiveManager';
import logger from '@core/logger';
import PaymentScenario from '@core/payment/common/constants/paymentScenario';
import PAYMENT_ONECLICK_FLOWS from '@core/payment/common/constants/paymentOneclickFlows';
import * as MOTIVATION_TEMPLATE_TYPE from '@phoenix/payment/widgets/motivation/constants/templateType';
import isPaymentUrl from '@core/utils/url/isPayUrl';
import routeVar from '@core/graphql/vars/routeVar';
import URI from '@core/utils/url';
import {EXTRA_DAY_VIAS} from '@core/payment/common/constants/vias';
import { getCookie } from '@core/utils/cookie';
import { localStorage } from '@core/utils/storage/storage';
import { ADULT_BIG_FONT_SIZE } from '@core/payment/common/constants/cookies';
import { LONG_FORM_FIELDS } from '@core/payment/common/constants/localStorageKeys';
import {getActiveSplitGroup, SPLITS} from '@core/utils/split';
import getPageButtonTranslate from '../utils/getPageButtonTranslate';
import PackageTypes from '@core/payment/widgets/package/constants/packageTypes';
import {
    CARD_HOLDER,
    CARD_NUMBER,
    EXPIRATION_DATE_M,
    EXPIRATION_DATE_Y,
} from '@core/payment/forms/card/constants/fieldNames';
import MethodsCollection from 'components/paymentPagePackage/models/MethodsCollection';
import getSelectedPackageDescriptionRule from 'components/paymentPageFooter/utils/getSelectedPackageDescriptionRule';
import { ACCOUNT_AND_BANK, EXTRA_TAB_NAME } from 'components/paymentPage/constants/sepaTabs';
import PAYMENT_METHODS, {
    IDEAL_METHODS,
    SEPA_METHODS,
    WALLET_METHODS,
    METHODS_WITHOUT_TAB
} from '@core/payment/common/constants/paymentMethods';
import getPayButtonTextSettings from '../utils/getPayButtonTextSettings';
import getWalletAllowedMethods from '../utils/getWalletAllowedMethods';
import {getClientInstance} from '@core/graphql/client';
import {
    getCachedPurchasedPackageData,
    removeCachedPurchasedPackageData
} from '@core/payment/utils/cachePurchasedPackageData';
import clearUrl from '@core/utils/url/clearUrl';
import DECLINE_VIA from '@core/payment/common/constants/declineVia';
import ADULT_CONTENT_AVAILABLE_QUERY from '@phoenix/user/motivation/graphql/queries/adultContentAvailable.gql';
import CACHED_USER_EXPANDED_QUERY from '@core/user/profile/target/graphql/queries/cachedUserExpanded.gql';
import isMicroFeaturesUrl from '@core/utils/url/isMicroFeaturesUrl';
import getBootstrapParam from '@core/application/utils/getBootstrapParam';
import PAYMENT_SOURCES from '@core/payment/common/constants/paymentSources';
import InAppBrowserName from '@core/application/constants/inAppBrowserName';
import ALLOWED_SEPA_NAMES from '@core/payment/common/constants/allowedSepaNames';
import { IN_APP_NAME } from '@core/application/constants/bootstrapParams';
import PaymentPageSuccessOrders from '@core/payment/payProcess/utils/PaymentPageSuccessOrders';
import isCardPaymentType from '@core/payment/forms/card/utils/isCardPaymentType';
import PAYMENT_PAGE_DETAILS_QUERY from '@phoenix/payment/common/graphql/queries/paymentPageDetails.gql';
import { CUSTOM_FOOTER_LAYOUT } from '@phoenix/payment/common/constants/paymentPageDetails';
import RESTRICTED_VIA_LIST from '@phoenix/payment/widgets/motivation/constants/restrictedViaList';
import updateCachesAfterPayment, {isUpdating} from '@phoenix/payment/payProcess/utils/updateCachesAfterPayment';
import isNull from 'lodash/isNull';
import compact from 'lodash/compact';

import isEmpty from 'lodash/isEmpty';
import getCorrectPaymentScene from 'components/paymentPage/utils/getCorrectPaymentScene';
import defaultController from 'components/cache/defaultController';
import currentUserId from 'components/cache/currentUserId';
import siteName from 'components/cache/siteName';
import getUser from '../utils/getUser';
import {ViaEnum} from '@core/types/graphql';
import PAYMENT_ACTIONS from '@core/payment/common/constants/paymentActions';


/**
 * Via`s for redirect to PP on click  user like or add favorite buttons
 * @type {(string)[]}
 */
const ADD_ACTION_VIAS = [ ViaEnum.add_like, ViaEnum.add_favor ];

/**
 * @class PaymentPageModel
 * @see BaseModel
 */
export default BaseModel.extend(_.extend({}, LoadOnDemandBehavior, {
    /**
     * @const {number}
     */
    TIME_CORRECTION: 1000,

    urlRoot: '/pay/membership/isJsonMode/true',

    // @deprecated Spit payment.20181204.malinovskiy.BU_34214 is failed, don't use on React PP
    ACTIVATION_FEE_PACKAGE_ID: '98',
    XSALE_PACKAGE_ID: '1',

    /**
     * @const {string}
     */
    PACKAGE_ID_DELIMITER: '_',

    /**
     * Need to cut text on sepa success page
     * @type {Array}
     */
    SEPA_METHODS_NAME: [
        ...Object.values(IDEAL_METHODS),
        ...Object.values(SEPA_METHODS),
    ],

    defaults: {
        /**
         * @type {array|null} list of selected additional packages
         */
        selectedAdditionalPackages: null,

        /**
         * Via that will be passed on API request
         * @see url()
         * @type {string}
         */
        via: null,

        /**
         * Need to track previous via in case when user leave
         * payment page. We need to set last one and retrive fresh data
         * @see PaymentPageModelFactory
         * @see __onPayFail()
         * @type {string}
         */
        prevVia: null,

        /**
         * true, if we have remembered card data and don't show card form
         * @type {boolean}
         */
        isCardFormVisible: true,

        /**
         * Need to hide payment descriptor
         * @type {boolean}
         */
        hideDescriptor: false,

        /**
         * Array of focused fields.
         */
        focusedFields: [],

        /**
         * Payment page model
         * @type {Object}
         */
        payModel: null,

        /**
         * Background image url that will be placed as background of page
         * @type {string}
         */
        pageBackgroundUrl: '',

        /**
         * Benefits availability for membership. Just pairs of key and boolean value
         * @type {Object}
         */
        paymentBenefits: {},

        /**
         * Array of absolude urls of motivation photos displayed in sidebar
         * @type {array}
         */
        sideBarPhoto: [],

        /**
         * Indicates - that there is payment in progress
         * @type {boolean}
         */
        isPaymentProcessing: false,

        /**
         * Indicates that if we can show fail form or hide it
         * @type {boolean}
         */
        showFailForm: true,

        /**
         * A redirect path after payment
         * @type {String}
         */
        returnPath: '',

        /**
         * A redirect path after successful payment if exists
         * @type {String}
         */
        successReturnPath: '',

        /**
         * @see PaymentPageMotivationBlock::processBanner
         * @type {Object}
         */
        motivationBannerSettings: {},

        /**
         * Number of pay page visit.
         */
        pageVisits: 0,

        /**
         * False if the last filled form caused a failed payment
         */
        formStatus: true,

        /**
         * @type {string}
         */
        extraTabName: ACCOUNT_AND_BANK,

        /**
         * We can get new long form fields after decline and we need to init validation
         * rules one more time.
         * @var {boolean}
         */
        isNeededChangeOfValidationRules: false,

        /**
         * Flag of additional pay confirmation checkbox status, only for card payment
         * @type {boolean}
         */
        additionalTermsChecked: null,

        /**
         * Flag of auto renewal agree
         * @type {boolean}
         */
        autoRenewalAgreeChecked: null,

        /**
         * Flag of additional pay confirmation checkbox status, only for credits and card payment
         * @type {boolean}
         */
        credentialsPolicyChecked: false,

        /**
         * Custom payment page details.
         */
        paymentPageDetails: [],

        isInstantRepeat: null,

        /**
         * Flag for check if card payment form is touched
         */
        formIsTouched: false,

        /**
         * Payment source. Need for calculate payment visits correctly.
         * For 'popup' source payment visits not increments.
         */
        source: PAYMENT_SOURCES.PAYMENT_PAGE,

        isButtonDisabled: false,

        /**
         * separate status for wallet button for
         * correct work in split international.20220919.ostapenko.BU_173502
         * @type {boolean}
         */
        isWalletButtonDisabled: false,
    },

    /**
     * point on current payment type
     */
    actionId: 'membership',

    /**
     * Remove onclick from html
     * @param data
     * @returns {object}
     */
    parse: function(data) {
        _.each(data.smsInstructions, function(instructions, name, list) {
            list[name] = this._removeOnclick(instructions);
        }.bind(this));

        // Remove unnecessary PayPal method in case when we have two versions - PayPal and PayPalv2
        if (data.packages?.[WALLET_METHODS.PAY_PAL] && data.packages[WALLET_METHODS.PAY_PAL_V2]) {
            delete data.packages[WALLET_METHODS.PAY_PAL];
        }

        if (data.paymentFooter) {
            data.paymentFooter = this._removeOnclick(data.paymentFooter);
        }

        if (data.user) {
            data.user = new PaymentPageUserModel(data.user);
        }

        if (data.extraHourTimer) {
            data.extraHourTimer *= this.TIME_CORRECTION;
        }

        if (data.motivationTemplate && !Object.values(MOTIVATION_TEMPLATE_TYPE).includes(data.motivationTemplate)) {
            data.motivationTemplate = null;
        }

        this.methodsCollection.removeNotReceived(keys(data.packages));

        _.each(data.packages, (packages, method) => {
            /**
             * Check if any method is cloned, and if it is in the list of clones, we take it
             * @type {string}
             */
            let methodName = method;
            if (data.tabPaymentForm) {
                methodName = data.tabPaymentForm[method] || methodName;
            }

            // Populating the collection of available methods
            if (this.canShowTabForPaymentMethod({ method, altMethodsScenario: data.altMethodsScenario })) {
                this.methodsCollection.addNew({
                    method: methodName,
                    originalMethod: method,
                });
            }

            _.each(_.values(packages), packageItem => {
                // Method name which may not contain the original name, since used when cloning a method
                packageItem.method = methodName;
                // Original unique method name
                packageItem.originalMethod = method;
                packageItem.packageId = packageItem.id;
                packageItem.id = packageItem.id + '_' + packageItem.originalMethod;
            });
        });

        const longFromFieldsForTestOnly = localStorage.getItem(LONG_FORM_FIELDS);

        if (longFromFieldsForTestOnly) {
            data.longFormFields = {
                longFormFields: longFromFieldsForTestOnly.longFormFields || [],
                anonymousAutoFillFields: longFromFieldsForTestOnly.anonymousAutoFillFields || [],
            };
        }

        if (this.get('payModel')?.get('changingPaymentDetails')) {
            data.altMethodsScenario[this.get('payModel').get('method')] = PaymentScenario.INITIAL;
        }


        const currentTab = this.methodsCollection.getActiveMethod();
        const currentDefaultTab = this.get('defaultActiveTab');
        const defaultActiveTab = data.defaultActiveTab;

        /**
         * Change active tab to default tab after decline if default tab is changed
         * @type {boolean}
         */
        const needUpdateDefaultTabAfterDecline = this.isDeclinedPay() &&
          Boolean(currentDefaultTab) &&
          Boolean(currentTab) &&
          (currentTab !== defaultActiveTab) &&
          (currentDefaultTab !== defaultActiveTab);

        if (needUpdateDefaultTabAfterDecline) {
            this.set('defaultActiveTab', defaultActiveTab);
            this.setDefaultMethodTab(true);
        }

        return data;
    },

    /**
     * @see /mobSite/components/paymentPage/models/PaymentPageModel.js
     * @return {Boolean}
     */
    isLifetimeTemplate() {
        return false;
    },

    /**
     * @see /mobSite/components/paymentPage/models/PaymentPageModel.js
     * @return {Boolean}
     */
    isTemplateWithPolicyAgreement() {
        return false;
    },

    /**
     * @return {Boolean}
     */
    isOneStepWithPolicyAgreement() {
        return false;
    },

    /**
     * @see /mobSite/components/paymentPage/models/PaymentPageModel.js
     * @return {Boolean}
     */
    isOneLinePPTemplate() {
        return false;
    },

    /**
     * @public
     * @return {Boolean}
     */
    isAllowedCreditAutofill() {
        return false;
    },

    /**
     * @public
     * @return {MethodsCollection}
     */
    getMethodsCollection() {
        return this.methodsCollection;
    },

    /**
     * @param html
     * @returns {string}
     * @private
     */
    _removeOnclick: function(html) {
        return html.replace(/(onclick="((?:\\"|[^"])*)")|(onclick='((?:\\'|[^'])*)')/g, '');
    },

    /**
     * Get iban object according to country code
     * @param country {string}
     * @return {Specification} - IBAN Object
     */
    getIban: function (country) {
        let countryCode = '';

        if (country) {
            countryCode = country.replace(/\s/g,'').slice(0, 2);
        } else {
            countryCode = this.get('country_code').toUpperCase();
        }

        if (countryCode.length > 2) {
            countryCode = getCountryISO2(countryCode);
        }

        return IBAN.countries[countryCode];
    },

    /**
     * @return {string}
     */
    url() {
        const uri = URI(this.urlRoot);
        uri.addSearch('via', this.get('via'));

        const viaProfileId = this.get('viaProfileId');
        const prevVia = this.get('prevVia');

        if (viaProfileId) {
            uri.addSearch('viaProfileId', viaProfileId);
        }
        if (prevVia) {
            uri.addSearch('prevVia', prevVia);
        }

        const packageId = this.getSelectedPackage()?.get('packageId');

        if (packageId) {
            uri.addSearch('packageId', packageId);
        }

        const source = this.get('source');

        if (source) {
            uri.addSearch('source', source);
        }

        return uri.toString();
    },

    applyFetch: function() {
        if (window.PP_RESP) {
            const applyAcceleration = () => {
                if (window.PP_RESP.resp?.data) {
                    this.set(this.parse(window.PP_RESP.resp.data));
                    this.trigger('sync');
                } else {
                    // fallback to model fetch if there is no prefethed data for some reason
                    this.fetch.apply(this, arguments);
                }

                delete window.PP_RESP;
            };

            if (window.PP_RESP.loading) {
                // For cases when /pay request takes more time, then app initialization time
                const waitInterval = setInterval(() => {
                    if (!window.PP_RESP.loading) {
                        applyAcceleration();
                        clearInterval(waitInterval);
                    }
                }, 50);

                return;
            }

            applyAcceleration();
        } else {
            this.fetch.apply(this, arguments);
        }
    },

    initialize: function() {
        this.set('inAppBrowser', getBootstrapParam(IN_APP_NAME) !== InAppBrowserName.NORMAL_BROWSER);

        this.methodsCollection = new MethodsCollection();
        this.collection = new PaymentPagePackageCollection(null, {
            paymentModel: this,
        });
        this.successOrdersModel = PaymentPageSuccessOrders;

        getActiveSplitGroup(
          SPLITS.BUY_SECURELY_PAY_BUTTON.ID,
          SPLITS.BUY_SECURELY_PAY_BUTTON.GROUP.ACTIVE
        ).then(result => {
            if (result && this.isMembershipStage()) {
                this.set('buySecurelyPayButton', true);
            }
        });

        getClientInstance().query({ query: PAYMENT_PAGE_DETAILS_QUERY }).then(({ data }) => {
            this.set('paymentPageDetails', data?.payment.paymentPageDetails || []);
        });

        if (!this.get('viaProfileId')) {
            this.getUser();
        }

        this.once('change:viaMethod', this.setPackageFromCache);
        this.once('sync', () => this.setDefaultMethodTab());
        this.once('ready', updatePaymentPageVisitsInCache);
        this.on('sync', this.onSync);
        this.once('sync', this.initSelectedAdditionalPackages);
        this.on('request', this.__validateModelVia);
        this.listenTo(this.methodsCollection, 'activeMethodSet', this.updatePayModel);
        this.listenTo(this.methodsCollection, 'activeMethodSet', this.initSelectedAdditionalPackages);
        this.on('change:isShowOneClick', this.onChangeOneClick);
        this.on('change:isCardFormVisible', this.fillCardScenario);
        this.on('change:longFormFields', this.__handleLongFormFieldsChange);
        this.on('change:formIsTouched', this.fillCardScenario);
        this.listenTo(this.collection, 'change:is_active', this._onActivePackageChange);
        this.on('pay:complete', this.__onPayComplete);
        this.on('pay:fail', this.__onPayFail);

        this.onPostMessageHandler = this.onPostMessage.bind(this);
        window.addEventListener('message', this.onPostMessageHandler);
        this.listenTo(this, 'remove', this.stopListenPostMessage.bind(this));
    },

    stopListenPostMessage() {
        window.removeEventListener('message', this.onPostMessageHandler);
    },

    onPostMessage(message) {
        const redirectUrl = message?.data?.externalPaymentInAnotherWindow?.redirectUrl;

        if (redirectUrl) {
            const { via, orderId } = URI(redirectUrl).search(true);
            const isDecline = DECLINE_VIA.includes(via);

            if (isDecline) {
                this.trigger('pay:fail', {
                    failPageLogic: via,
                });
            } else {
                if (orderId) {
                    PaymentPageSuccessOrders.add(orderId, via);
                }

                window.app.router.navigate(clearUrl(redirectUrl), {
                    trigger: true,
                });
                updateCachesAfterPayment();
                this.stopListenPostMessage();
            }
        }
    },

    /**
     *
     * Cache the selected package when the user navigates to external payment site
     * and restore the selected package if the user returns.
     * @see cachePurchasedPackageData.js
     * @private
     */
    setPackageFromCache() {
        const cachedPurchasedPackageData = getCachedPurchasedPackageData();

        if (!cachedPurchasedPackageData) {
            return;
        }

        const { method, packageId } = cachedPurchasedPackageData;

        const viaMethod = this.get('viaMethod');

        if (viaMethod === method && packageId) {
            this.setActivePackage(packageId);
        }

        removeCachedPurchasedPackageData();
    },

    isBillingPolicyWithPackageDescription() {
        return this.isMembershipStage() &&
            this.get('via') !== ViaEnum.remarketing_show_popup &&
            this.get('paymentPageDetails').includes(CUSTOM_FOOTER_LAYOUT);
    },

    onSync: function() {
        this.__processAdditionalTermsStatusMap();
        this.processCollection();
        this.updatePayModel();
    },

    /**
     * @public
     * @param {string} additionalPackageType
     * @param {boolean} isSelected
     */
    setSelectedAdditionalPackage(additionalPackageType, isSelected) {
        let selectedAdditionalPackages = compact([].concat(this.get('selectedAdditionalPackages')));

        if (isSelected) {
            if (!selectedAdditionalPackages.includes(additionalPackageType)) {
                selectedAdditionalPackages.push(additionalPackageType);
            }
        } else {
            selectedAdditionalPackages = without(selectedAdditionalPackages, additionalPackageType);
        }

        this.set('selectedAdditionalPackages', selectedAdditionalPackages);
    },

    /**
     * @public
     * @return {boolean}
     */
    isGuaranteesAllowed() {
        const activeMethod = this.getMethodsCollection().getActive();

        return activeMethod && ALLOWED_SEPA_NAMES.includes(activeMethod.get('method'));
    },

    /**
     * Check if selected method can display tab
     * @public
     * @return {Boolean}
     */
    canShowTabForPaymentMethod({ method, altMethodsScenario }) {
        if (includes(METHODS_WITHOUT_TAB, method)) {
            return false;
        }

        if (method === PAYMENT_METHODS.CCBILL) {
            return this.isOneClickByScenario(altMethodsScenario?.[PAYMENT_METHODS.CCBILL]);
        }

        return true;
    },

    /**
     * @public
     * @return {Boolean}
     */
    isPayPalOneClick() {
        const settings = this.get('altMethodsSettings')[WALLET_METHODS.PAY_PAL_V2];
        const scenario = this.get('altMethodsScenario')[WALLET_METHODS.PAY_PAL_V2];

        if (!settings || !scenario) {
            return false;
        }

        return settings.isOneClickForm && this.isOneClickByScenario(scenario) && this.isCardMethod();
    },

    /**
     * @param {String} scenario
     * @return {Boolean}
     * @public
     */
    isOneClickByScenario: function (scenario) {
        return scenario === PaymentScenario.ONECLICK;
    },

    /**
     * Sepa mandate masked fields for current method
     * @public
     * @return {Object|null}
     */
    getSepaMandateFields() {
        return this.get('altMethodsSettings')?.[this.getMethodsCollection().getActiveMethod()]?.maskedFields || null;
    },

    _onActivePackageChange() {
        /** Set additional package default checkbox state */
        this.initSelectedAdditionalPackages();
    },

    initSelectedAdditionalPackages() {
        if (!this.isAdditionalPackagesAvailable() || !isNull(this.get('selectedAdditionalPackages'))) {
            return;
        }

        const packageList = this.getAllAdditionalPackages(
            this.getMethodsCollection().getActiveMethod(),
            this.getSelectedPackage().get('package').id
        );

        packageList.forEach(packageItem => {
            const {
                isCheckedByDefault,
                isXsaleCheckBoxLogicInverted,
                additionalPackageType,
            } = packageItem;

            if (this.isAdditionalPackagesAvailable(additionalPackageType)) {
                this.setSelectedAdditionalPackage(
                    additionalPackageType,
                    isXsaleCheckBoxLogicInverted ? !isCheckedByDefault : isCheckedByDefault
                );
            }
        });
    },

    /**
     * Definition is checked autoRenewalAgree and billingPolicy checkboxes
     * @private
     */
    __processAdditionalTermsStatusMap() {
        const {
            billingPolicy,
            agreeAndContinue,
            autoRenewalAgree,
            credentialsPolicy,
        } = this.get('additionalTermsStatusMap') || {};

        let billingPolicyDefaultValue = null;

        if (billingPolicy !== undefined) {
            billingPolicyDefaultValue = billingPolicy;
        }

        if (agreeAndContinue !== undefined) {
            billingPolicyDefaultValue = agreeAndContinue;
        }

        if (billingPolicyDefaultValue !== null) {
            this.set('additionalTermsChecked', billingPolicyDefaultValue);
        }

        if (autoRenewalAgree !== undefined && !this.get('autoRenewalAgreeChecked')) {
            this.set('autoRenewalAgreeChecked', autoRenewalAgree);
        }

        if (this.isCredentialsPolicyNeeded()) {
            this.set('credentialsPolicyChecked', credentialsPolicy);
        }
    },

    /**
     * @return {boolean}
     * @public
     */
    isNovalNetSepa() {
        return this.getMethodsCollection().getActiveMethod() === PAYMENT_METHODS.NOVAL_NET_SEPA;
    },

    isCCBillOnDeclineActive() {
        return PAYMENT_METHODS.CCBILL in this.get('packages');
    },

    /**
     * @return {boolean}
     */
    isAllowedBicList() {
        const method = this.getMethodsCollection().getActiveMethod();

        return [ PAYMENT_METHODS.TRUST_PAY_IDEAL, PAYMENT_METHODS.NUVEI_IDEAL ].includes(method);
    },

    /**
     * @return {boolean}
     * @public
     */
    isCardMethod() {
        return PAYMENT_METHODS.CARD === this.getMethodsCollection().getActiveMethod();
    },

    needToHideDescriptor: function () {
        return this.get('hideDescriptor') || !this.__isMethodsWithDescriptor();
    },

    /**
     * return footer data
     */
    getFooterParts() {
        const footer = this.get('footer') || {};

        return footer.blockDataMap || {};
    },

    /**
     * return isStrictLegalMode
     */
    getIsStrictLegalMode() {
        const footer = this.get('footer') || {};

        return get(footer,'displaySettingData.isStrictLegalMode',false);
    },


    /**
     * if current user not the one from which we came to payment page, return it.
     * return random user from search otherwise
     * @return {Promise}
     */
    getUser: function () {
        return getUser({
            viaProfileId: this.get('viaProfileId'),
            isUserMotivation: this.isUserMotivation(),
            userFromModel: this.get('user'),
            query: CACHED_USER_EXPANDED_QUERY,
            PaymentPageUserModel,
        }).then(user => {
            this.set('user', user);
            return user;
        });
    },

    /**
     * @return {boolean}
     */
    isCurrentMethodForbidden: function () {
        return ViaEnum.sepa_off === this.get('via');
    },

    /**
     * @public
     * @return {boolean}
     */
    isUserMotivation: function () {
        const isUserMotivation = !!this.get('viaProfileId');

        if (isUserMotivation && !this.get('user')) {
            logger.sendWarning(`There is no user model for via = ${this.get('via')}`);

            return false;
        }

        return isUserMotivation;
    },

    /**
     * @public
     * @param url {string}
     * @return {string}
     */
    prepareRedirectUrl: function (url) {
        if (!url) {
            return defaultController();
        }

        const upgradeUrl = URI(url);
        const viaProfileId = this.get('viaProfileId');

        upgradeUrl.origin('');

        upgradeUrl.setSearch('via', this.getCurrentPaymentStage());

        const returnPath = this.getReturnPath();
        const successReturnPath = this.getSuccessReturnPath();

        if (!upgradeUrl.hasSearch('returnPath') && returnPath) {
            upgradeUrl.addSearch('returnPath', returnPath);
        }

        if (successReturnPath) {
            upgradeUrl.addSearch('successReturnPath', successReturnPath);
        }

        if (viaProfileId) {
            upgradeUrl.addSearch('viaProfileId', viaProfileId);
        }

        return upgradeUrl.toString();
    },

    prepareReturnPath: function (param) {
        if (this.get(param)) {
            return decodeURIComponent(this.get(param));
        }

        const url = URI(window.location);
        if (url.hasSearch(param)) {
            return decodeURIComponent(url.query(true)[param]);
        }

        return '';
    },

    /**
     * successReturnPath stored in decoded format @see URI
     * @public
     * @returns {string}
     */
    getSuccessReturnPath: function () {
        return this.prepareReturnPath('successReturnPath');
    },

    /**
     * returnPath stored in decoded format @see URI
     * @public
     * @returns {string}
     */
    getReturnPath: function () {
        return this.prepareReturnPath('returnPath');
    },

    /**
     * @private
     */
    __onPayComplete: function() {
        // Remove current banner type.
        // Needed for last step when we need to remove by itself fully banner
        this.unset('bannerData');
    },

    /**
     * Handle PayModel fail.
     * Set prevVia as current via except second failed payment to avoid via=inpage_decline&prevVia=inpage_decline.
     * @private
     * @param {Object} data
     */
    __onPayFail(data) {
        this.set({
            prevVia: this.getPrevVia(),
            via: data.failPageLogic,
            formStatus: Boolean(this.get('payModel')?.isAltOneClick?.()) || Object.values(WALLET_METHODS).includes(data.method),
            showFailForm: true,
        });
    },

    /**
     * @public
     * @return {boolean}
     */
    isAccountAndBank() {
        return this.get(EXTRA_TAB_NAME) === ACCOUNT_AND_BANK;
    },

    /**
     * Create pay model by params
     * @param {boolean} isCardType
     * @param {object} params
     * @returns {*}
     */
    createPayModelFromFactory: function (isCardType, params) {
        return PaymentPagePayModelFactory.getModel(isCardType, params);
    },

    /**
     * @public
     */
    updatePayModel() {
        const longFormFields = this.get('longFormFields') || {};
        const altMethodsSettings = this.get('altMethodsSettings') || {};
        const isIdealAutofillData = Boolean(
            altMethodsSettings[PAYMENT_METHODS.TRUST_PAY_IDEAL] ||
                altMethodsSettings[PAYMENT_METHODS.NUVEI_IDEAL] ||
                altMethodsSettings[PAYMENT_METHODS.NUVEI_MY_BANK]
        );
        const isEmptyFormAfterDecline = this.get('payModel')?.get('isEmptyFormAfterDecline');

        this.set('payModel', this.createPayModelFromFactory(this.isCardPaymentType(), {
            altMethodsScenario: this.get('altMethodsScenario'),
            altMethodsSettings,
            userAttributes: this.get('userAttributes'),
            method: this.methodsCollection.getActiveMethod(),
            via: this.getCurrentPaymentStage(),
            additionalFields: longFormFields.longFormFields,
            sepaAutofillAccess: this.get('sepaAutofillAccess'),
            isInstantRepeat: this.get('isInstantRepeat'),
            isIdealAutofillData: isIdealAutofillData,
            sepaMandateFields: this.getSepaMandateFields(),
            isAccountAndBank: this.isAccountAndBank(),
            formattedPhonePrefix: this.getFormattedPhonePrefix(),
            isEmptyFormAfterDecline,
        }));

        var payModel = this.get('payModel'),
            previousPayModel = this.previous('payModel');

        const paymentSystems = this.get('paymentSystems');
        // Remember previous data when payment is declined
        const { debitType, financialServicePlatform, bin, cardTypeLogo } = this.get('payModel')?.get('currentBinEntity') || {};

        if (previousPayModel) {
            this.stopListening(previousPayModel);

            if (isCardPaymentType(previousPayModel.get('method'))) {
                previousPayModel.set({
                    [CARD_HOLDER]: '',
                    [CARD_NUMBER]: '',
                    [EXPIRATION_DATE_M]: '',
                    [EXPIRATION_DATE_Y]: '',
                });
            } else if (previousPayModel.get('changingPaymentDetails') && previousPayModel.get('method') !== payModel.get('method')) {
                // Reset changing payment details for sepa mandate form
                previousPayModel.unset('changingPaymentDetails');
                this.get('altMethodsScenario')[previousPayModel.get('method')] = PaymentScenario.ONECLICK;
            }
        }

        if (payModel) {
            this.isCardPaymentType() && payModel.resetBannedBins();
            this.listenPayModel(payModel);
        }

        if (this.isDefaultCardPaymentType()) {
            payModel?.set('cardTypeRules', this.get('cardTypeRules'));

            if (paymentSystems && !this.get('isCardFormVisible')) {
                payModel?.set('currentBinEntity', {
                    bin: paymentSystems.card.bin,
                    financialServicePlatform: paymentSystems.card.financialServicePlatform,
                    debitType: paymentSystems.card.debitType,
                    cardTypeLogo: paymentSystems.card.cardTypeLogo
                });
                payModel?.setBinEntity(payModel?.get('currentBinEntity'));
            } else {
                payModel?.set('currentBinEntity', {
                    bin,
                    debitType,
                    financialServicePlatform,
                    cardTypeLogo,
                });
            }
        }

        /** Need to save valid card payment scenario */
        this.fillCardScenario();

        const isPaymentPreviousRoute = isPaymentUrl(routeVar().previous);

        if (!isPaymentPreviousRoute) {
            this.successOrdersModel.clearStoredOrders(this.get('via'));
        }
    },

    listenPayModel: function(payModel) {
        this.listenTo(payModel, 'all', this.__onPayModelAll);
    },

    /**
     * @private
     * @param {String} event Event name
     */
    __onPayModelAll: function (event) {
        // Proxy this events from payModel to self.
        if (
            _.includes([
                'pay:complete',
                'pay:fail',
                'pay:success',
                'pay:startExternalPayment',
            ], event))
        {
            this.trigger.apply(this, arguments);
        }
    },

    /**
     * Check if is allowed one click payment with opened card form
     * @return {boolean}
     * @protected
     */
    isOneClickPaymentWithOpenedCardForm() {
        return this.get('isShowOneClick') && this.get('isCardFormVisible') &&
          !this.isPayPalOneClick() &&
          !this.isOneClickWithCvvAvailable() &&
          !this.get('formIsTouched');
    },

    isOneClickWithCvv() {
        return this.isOneClickWithCvvAvailable() && !this.get('isCardFormVisible');
    },

    isOneClickWithCvvAvailable() {
        return this.get('isShowOneClick') &&
            this.get('paymentSystems')?.card?.oneClickFlow === PAYMENT_ONECLICK_FLOWS.USE_CVV;
    },

    isOneClickSimpleAvailable() {
        return this.get('isShowOneClick') &&
            this.get('paymentSystems')?.card?.oneClickFlow === PAYMENT_ONECLICK_FLOWS.SIMPLE;
    },

    /**
     * @return {number}
     * @protected
     */
    isHidePaymentForm() {
        if (this.isOneClickPaymentWithOpenedCardForm()) {
            return 1;
        }

        return Number(!this.get('isCardFormVisible'));
    },

    /**
     * Get payment scenario
     * @return {string}
     * @protected
     */
    getCardPaymentScenario() {
        if (this.isOneClickPaymentWithOpenedCardForm()) {
            return PaymentScenario.ONECLICK;
        }

        return (this.get('isCardFormVisible') || this.get('cardWallet')) ? PaymentScenario.INITIAL :
            PaymentScenario.ONECLICK;
    },

    /**
     * update card payment model scenario, according card form visibility
     * @private
     */
    fillCardScenario() {
        if (!this.get('payModel')) {
            return;
        }

        if (this.isCardPaymentType()) {
            this.get('payModel').set('scenario', this.getCardPaymentScenario());
            this.get('payModel').setScenario(this.getCardPaymentScenario());
        }
    },

    /**
     * @public
     */
    resetUserSelections: function() {
        delete this._tempActivePackageId;
        this.set('selectedAdditionalPackages', null);
        this.unset('user');
        this.setDefaultMethodTab();
    },

    /**
     * @public
     * @param {String|String[]} methods
     * @returns {Object}
     */
    getMethodPackages: function (methods) {
        const packages = this.get('packages');
        return packages && pick(packages, Array.isArray(methods) ? methods : [methods]);
    },

    /**
     *
     * @param {string} method
     * @param {number} packageId
     * @return {array} packages
     * @public
     */
    getAllAdditionalPackages: function (method, packageId) {
        return this.__getAdditionalPackages(method, packageId);
    },

    /**
     * Get a specific additional package by the package params
     *
     * @param {string} method
     * @param {number} packageId
     * @param {object} params
     * @return {object}
     * @public
     */
    getAdditionalPackageByParams: function (method, packageId, params) {
        return find(this.__getAdditionalPackages(method, packageId), params) || {};
    },

    /**
     * @param method
     * @param packageId
     * @return []
     * @private
     */
    __getAdditionalPackages: function (method, packageId) {
        const packagesByMethod = this.get('additionalPackages') ?
            this.get('additionalPackages')[method] :
            false;

        if (!packagesByMethod) {
            return [];
        }

        return _.values(packagesByMethod[packageId]);
    },

    /**
     * @return {{} || undefined}
     * @public
     */
    getActivationFeeAdditionalPackage: function () {
        const additionalPackages = this.__getAdditionalPackages(
          this.getMethodsCollection().getActiveMethod(),
          this.getSelectedPackage().get('package').id
        );

        return _.find(additionalPackages, {
            id: this.ACTIVATION_FEE_PACKAGE_ID,
        });
    },

    /**
     * Log syncs of PP model without via, via is required for correct work of back-end
     * @private
     */
    __validateModelVia(model) {
        const via = model.get('via');

        try {
            if (_.isEmpty(via)) {
                throw new Error('There was request at payment model without via.');
            }

            if (via === 'undefined') {
                throw new Error('There was request at payment model with undefined via.');
            }
        } catch (e) {
            logger.captureException(e);
            console.error(e);
        }
    },

    /**
     * Check if allowed additional packages as list (or specific package if you find by type)
     * @param {null|string} additionalPackageType
     * @return {boolean}
     */
    isAdditionalPackagesAvailable(additionalPackageType = null) {
        const selectedPackages = this.getSelectedPackage();

        if (!selectedPackages) {
            return false;
        }

        const additionalPackageList = this.getAllAdditionalPackages(
            this.methodsCollection.getActiveMethod(),
            selectedPackages.get('package').id
        );

        let isExistsPackages = !isEmpty(additionalPackageList);
        if (additionalPackageType) {
            const additionalPackage = find(additionalPackageList, {
                additionalPackageType,
            });
            isExistsPackages = !isEmpty(additionalPackage);
        }

        return Boolean(isExistsPackages && !_.includes(this.PAID_FUNNEL_VIA, this.get('via')) &&
            this.getFooterParts().additionalPackages);
    },

    /**
     * Crutch the parameter by which the package descriptor is transferred from the footer to the description block
     * @public
     * @returns {boolean}
     */
    isComplianceFriendlyAllowed() {
        return (this.get('templateSettings') && this.get('templateSettings').termsLayout === 'complianceFriendly') &&
            isPaymentUrl(location.pathname);
    },

    /**
     * Set default method as active
     * @private
     */
    setDefaultMethodTab: function (skipSetFromViaMethod = false) {
        if (this.isRemarketingOfferStage() && this.get('defaultActiveTab') !== PAYMENT_METHODS.CARD) {
            logger.sendWarning(`'defaultActiveTab: ${this.get('defaultActiveTab')}' is unacceptable for posttrans`);
            this.set('defaultActiveTab', PAYMENT_METHODS.CARD);
        }

        this.methodsCollection.setActive(this.get('defaultActiveTab'));

        const viaMethod = this.get('viaMethod');

        const methodWithTabs = this.canShowTabForPaymentMethod({
            method: viaMethod,
            altMethodsScenario: this.get('altMethodsScenario')
        });

        /**
         * Set method from url as default if it exist
         * Exclude  WALLET_METHODS. @see paypal method
         */
        if (
            !skipSetFromViaMethod &&
            viaMethod &&
            methodWithTabs &&
            this.__isPaymentMethodExists(viaMethod)
        ) {
            this.methodsCollection.setActive(viaMethod);
        }
    },

    /**
     * is need to mark checkbox before pay
     * @returns {boolean}
     */
    isAdditionalTermsNeeded() {
        const additionalTermsStatusMap = this.get('additionalTermsStatusMap');
        const billingPolicy = additionalTermsStatusMap?.billingPolicy;
        const agreeAndContinue = additionalTermsStatusMap?.agreeAndContinue;

        const isAdditionalTerms = ((billingPolicy !== undefined || agreeAndContinue !== undefined)) &&
          this.isCardPaymentType() && isPaymentUrl(location.pathname);

        const isWithBillingPolicy = getSelectedPackageDescriptionRule({
            footerParts: this.getFooterParts(),
            packageId: this.getSelectedPackage()?.get('package').id,
        })?.isWithBillingPolicy;

        if (isAdditionalTerms && isWithBillingPolicy) {
            logger.sendWarning(
                `[PaymentPageModel] Footer part "PackageDescriptionWithBillingPolicy" doesn't support additional terms "billingPolicy" and "agreeAndContinue"`
            );

            return false;
        }

        return isAdditionalTerms && !isWithBillingPolicy;
    },

    /**
     * is need to mark checkbox autoRenewalAgree before pay
     * @returns {boolean}
     */
    isAutoRenewalAgreeNeeded() {
        const additionalTermsStatusMap = this.get('additionalTermsStatusMap');

        return this.isDefaultCardPaymentType() && additionalTermsStatusMap?.autoRenewalAgree !== undefined;
    },

    /**
     * @public
     * @return {Boolean}
     */
    getButtonLockCondition() {
        return this.getButtonProcessingCondition() || this.isCardPayButtonLocked();
    },

    getButtonProcessingCondition() {
        return this.get('isPaymentProcessing') || this.get('loading') || isUpdating();
    },

    getPackageDescriptionCheckboxDataName() {
        return this.isAutoRenewalAgreeNeeded() ? 'autoRenewalAgreeChecked': '';
    },

    /**
     * is need to change position of additional package and add animation
     * @returns {boolean}
     */
    isAdditionalTermsUpdated() {
        return this.isAdditionalTermsNeeded() && includes(this.get('additionalTerms'), 'autoRenewalAgreeXSale');
    },

    /**
     * Get custom block from footer html
     * @param {string} $selector
     * @returns {string || undefined}
     * @public
     */
    getBlockFromFooter($selector) {
        const $wrapper = $(htmlStringToElements(this.get('paymentFooter')));
        const $block = $wrapper.find($selector)[0];

        return $block && $block.outerHTML;
    },

    /**
     * is need to mark checkbox before pay for Credit
     * @returns {boolean}
     */
    isCredentialsPolicyNeeded() {
        const billingPolicyData = this.getFooterParts().TextBillingPolicy;

        return !!(billingPolicyData && billingPolicyData.isShowCredentialsPolicy && this.isCardPaymentType() &&
          isPaymentUrl(location.pathname));
    },
    /**
     * @public
     * @return {boolean}
     */
    isAddActionVias() {
        return ADD_ACTION_VIAS.includes(this.get('via'));
    },

    /**
     * is card pay button locked due unchecked additional terms or credentialsPolicy
     * @returns {boolean}
     */
    isCardPayButtonLocked() {
        if (!this.isCardPaymentType()) {
            return false;
        }

        return (this.isAdditionalTermsNeeded() && !this.get('additionalTermsChecked')) ||
          (this.isCredentialsPolicyNeeded() && !this.get('credentialsPolicyChecked'));
    },

    /**
     * Is exist provided or current method
     * @param method
     * @returns {boolean}
     * @private
     */
    __isPaymentMethodExists: function(method = '') {
        method = method ? method : this.methodsCollection.getActiveMethod();

        return !_.isUndefined(method) && !_.isEmpty(this.getMethodPackages(method));
    },

    /**
     * @public
     */
    processCollection: function() {
        var resultPackages = _.reduce(this.get('packages'), function (memo, packages) {
            memo.push.apply(memo, _.values(packages));
            return memo;
        }, []);

        /**
         * We defer update collection after response received,
         * and change packages use collection.set function with {parse: true, add: true, remove: true, merge: true} options
         */
        this.collection.set(resultPackages, {parse: true, add: true, remove: true, merge: true});

        if (this._tempActivePackageId) {

            // Need check when packages are existed
            if (!this.__isCorrectPackageId(this._tempActivePackageId)) {
                this._tempActivePackageId = this.__createPackageIdString(this._tempActivePackageId);
            }

            const activePackage = this.collection.find({
                id: this._tempActivePackageId
            });

            if (activePackage) {
                this.methodsCollection.setActive(activePackage.get('originalMethod'));
                this.collection.setActive(this._tempActivePackageId);
                delete this._tempActivePackageId;
            }
        }
    },

    /**
     * @return {PaymentPagePackageModel}
     * @public
     */
    getSelectedPackage: function () {
        return this.collection.where({
            originalMethod: this.methodsCollection.getActiveOriginalMethod(),
            is_active: true,
        }, true);
    },

    /**
     * Returns total number of available packages for selected method
     * @public
     * @return {number}
     */
    getPackagesCountForCurrentMethod: function () {
        if (!this.get('packages') || this.get('packages').length === 0) {
            return 0;
        }

        const packages = this.get('packages')[this.getMethodsCollection().getActiveOriginalMethod];
        return Object.keys(packages || {}).length;
    },

    /**
     * Get static photo url from API
     * @return {string} photo url
     * @public
     */
    getRandomStaticPhoto: function() {
        var photos = this.get('sideBarPhoto');
        return photos[Math.round(Math.random() * photos.length)];
    },

    /**
     * Get available methods
     * @param {boolean} excludePhoneMethods if true - get methods excluding phone methods
     * @public
     * @return {array}
     */
    getAvailableMethods(excludePhoneMethods) {
        return this.getMethodsCollection().models;
    },

    /**
     * @summary Check packageId has correct format
     * @param  {string} packageId
     * @returns {boolean}
     * @private
     */
    __isCorrectPackageId(packageId) {
        return packageId.indexOf(this.PACKAGE_ID_DELIMITER) >= 0;
    },

    /**
     * @important Use when methodTab prop is available
     * @param  {string} packageId
     * @returns {string}
     * @private
     */
    __createPackageIdString(packageId) {
        return packageId + this.PACKAGE_ID_DELIMITER + this.methodsCollection.getActiveMethod();
    },

    /**
     * set active package in collection or preset it before collection create,
     * navigate to main page in there are no id
     * @param {string} packageId
     * @returns {*}
     * @public
     */
    setActivePackage: function(packageId) {
        if (this.collection.length) {
            this.collection.setActive(packageId);
        } else {
            this._tempActivePackageId = packageId;
        }

        return this;
    },

    /**
     * @public
     * @return {string|null}
     */
    getCorrectedScene() {
        return getCorrectPaymentScene.call(this);
    },

    /**
     * @returns {Boolean}
     * @public
     */
    isPayMethodSepa: function() {
        return _.includes(this.SEPA_METHODS_NAME, this.get('viaMethod'));
    },

    /**
     * @returns {string}
     * @protected
     */
    _getFormAction: function() {
        return ''
    },

    /**
     * return specified key for USA users for antifraud text key
     * @public
     */
    getAntifraudTextKey: function() {
        if (this.get('country_code') === 'USA') {
            return 'antifraudTerms.text1_USA';
        }
        return 'antifraudTerms.text1';
    },

    /**
     * Get payment banner type
     * @returns {string} banner type
     */
    getBannerType: function() {
        var bannerData = this.get('bannerData');

        if (!bannerData) {
            return null;
        }
        return _.keys(bannerData)[0];
    },

    /**
     * @return {boolean}
     * @public
     */
    needToShowDiscountMotivation: function () {
        if (this.getCurrentPaymentStage() === 'features') {
            return false;
        }

        if (this.get('discount') && this.getCorrectedScene()) {
            return true;
        }

        return false;
    },

    /**
     * @see PaymentPageMotivationBannerView
     * @return {boolean}
     * @public
     */
    isSpecialPackagesVia: function () {
        return _.includes(RESTRICTED_VIA_LIST, this.get('via'));
    },

    /**
     * @return {boolean}
     * @public
     */
    isDeclinedPay: function () {
        return DECLINE_VIA.includes(this.get('via'));
    },

    /**
     * @return {Boolean}
     */
    isTrialMembershipSelectedPackage() {
        const packageTypes = this.getSelectedPackage()?.get('package')?.packageTypes || [];
        return packageTypes.includes(PackageTypes.TRIAL) && packageTypes.includes(PackageTypes.MEMBERSHIP);
    },

    /**
     * Get payment url for current banner
     * @returns {string|null}
     */
    getBannerPayUrl: function() {
        var type = this.getBannerType();

        if (!type) {
            return null;
        }
        return this.get('bannerData')[type];
    },

    /**
     * Retrive current payment stage (type).
     * Can be 'membership', 'features', etc.
     * @returns {string}
     */
    getCurrentPaymentStage: function () {
        return this.actionId;
    },

    /**
     * @public
     * @return {boolean}
     */
    isMembershipStage() {
        return this.getCurrentPaymentStage() === PAYMENT_ACTIONS.MEMBERSHIP;
    },

    /**
     * @public
     * @return {boolean}
     */
    isRemarketingOfferStage() {
        return this.getCurrentPaymentStage() === PAYMENT_ACTIONS.REMARKETING_OFFER;
    },

    /**
     * get array of logos from response object
     * @return {Array}
     * @private
     */
    getSecurityLogos() {
        const securityLogos = this.get('securityLogos');
        const logosForOriginalMethod = securityLogos[this.methodsCollection.getActiveOriginalMethod()];

        let logosForMethod = securityLogos[this.methodsCollection.getActiveMethod()] || [];

        // For some clones of card method we must show logos for original method if they exist!
        if (this.isCardPaymentType() && !_.isEmpty(logosForOriginalMethod)) {
            logosForMethod = logosForOriginalMethod;
        }

        return _.map(logosForMethod, (logo) => {
            return logo.toLowerCase();
        });
    },

    /**
     * Set data about user and chosen package and set payment logic to processing status
     * @public
     */
    processPayment() {
        const payModel = this.get('payModel');
        const selectedPackage = this.getSelectedPackage();

        const autoRenewalAgreeData = {};
        if (this.isAutoRenewalAgreeNeeded()) {
            autoRenewalAgreeData.autoRenewalAgree = this.get('autoRenewalAgreeChecked');
        }

        payModel.set({
            scenario: this.getCardPaymentScenario(),
            oneClickFlow: this.get('paymentSystems')?.card?.oneClickFlow || PAYMENT_ONECLICK_FLOWS.SIMPLE,
            package_id: selectedPackage.get('packageId'),
            product_id: selectedPackage.get('package').permission_set_id,
            currency_code: selectedPackage.get('price').currency.literal.code,
            user_id: currentUserId(),
            domain: siteName(),
            locale: this.get('locale'),
            country_code: this.get('country_code'),
            selectedAdditionalPackages: this.get('selectedAdditionalPackages'),
            hidePaymentForm: this.isHidePaymentForm(),
            prevVia: this.getPrevVia(),
            isInstantRepeat: this.get('isInstantRepeat'),
            ...autoRenewalAgreeData
        });

        this.set('isPaymentProcessing', true);
    },

    /**
     * @public
     * @returns {String}
     */
    getPrevVia() {
        return this.isDeclinedPay() ? this.get('prevVia') : this.get('via');
    },

    /**
     * Set stop processing state
     * @param {boolean} isProcessing
     */
    togglePaymentProcessing: function (isProcessing) {
        isProcessing = isProcessing === undefined ? !this.get('isPaymentProcessing') : isProcessing;
        this.set('isPaymentProcessing', isProcessing);
    },
    /**
     * Run directives and run callback if in case success response
     * Directives starts server validations and checks server permissions before pay
     * @param {PaymentPageBaseDirectiveModel} directiveModel
     * @param {function} callback - running after directive response
     * @return {PaymentPageDirectiveManager}
     */
    runDirectives: function (directiveModel, callback) {
        return new PaymentPageDirectiveManager({
            model: this.get('payModel'),
            callback: callback,
            directiveModel: directiveModel
        });
    },
    /**
     * Start payment process on server
     * @param {Object} options - pay options and callbacks
     *                 options.success - callback on payment success
     *                 options.error - callback on payment error
     *                 options.invalid - callback on server validation error
     */
    pay(options) {
        options = options || {};
        const { success, error, invalid } = options;

        this.get('payModel').save(null, {
            success,
            error,
            invalid,
            complete: () => {
                this.set('isPaymentProcessing', false);
            }
        });
    },

    /**
     * Set payment form attributes to pay model and validate
     * @param {Object} attributes - payment form attributes
     * @param {String} validateMethod
     * @return {Array}
     */
    __validateAndSet(attributes, validateMethod) {
        const payModel = this.get('payModel');

        const errorsToShow = payModel[validateMethod](attributes);

        if (!payModel.hasErrors()) {
            payModel.set(attributes);
        }

        return errorsToShow;
    },

    /**
     * @param {Object} attributes
     * @return {Array}
     */
    validateAndSetFormAttributes(attributes) {
        return this.__validateAndSet(attributes, 'validate');
    },

    /**
     * Checking attributes only those that are in the form
     * @param {Object} attributes
     * @return {Array}
     */
    validateByAttributesAndSet(attributes) {
        Object.keys(attributes).forEach(attribute => {
            this.get('payModel').addValidateAttribute(attribute);
        });

        return this.__validateAndSet(attributes, 'partialValidate');
    },

    /**
     * @return {number}
     * @public
     */
     getDiscount: function() {
        const method = this.getMethodsCollection().getActiveMethod();
        const altMethodDiscount = this.get('altMethodDiscounts')?.[method]?.percent;
        let discount = this.get('discount')?.percent || 0;

        if (!this.isDefaultCardPaymentType()) {
            discount = altMethodDiscount ? altMethodDiscount : discount;
        }

        return discount;
    },

    /**
     * We can get new long form fields after decline and we need to init validation
     * rules one more time.
     * @private
     */
    __handleLongFormFieldsChange: function () {
        this.set('isNeededChangeOfValidationRules', true);
    },

    onChangeOneClick(model, isShowOneClick) {
        this.toggleCardFormVisible(!isShowOneClick);
    },

    /**
     * @param {boolean} isNeedShow
     * @public
     */
    toggleCardFormVisible: function (isNeedShow) {
        isNeedShow = isNeedShow === undefined ? !this.get('isCardFormVisible') : isNeedShow;
        this.set('isCardFormVisible', isNeedShow);
    },

    /**
     * Abstract payment method type
     *  for checking if current method is default card method
     * @returns {boolean}
     */
    isDefaultCardPaymentType() {
        return this.getMethodsCollection().getActiveMethod() === PAYMENT_METHODS.CARD;
    },
    /**
     * Abstract payment method type
     * for different alt methods
     * to run as card method type
     * @returns {boolean}
     */
    isCardPaymentType() {
        return isCardPaymentType(this.__getMethod());
    },

    /**
     * @private
     * @return {Promise<boolean>}
     */
    __isWalletMethodsForAltAvailable: async function () {
        return !this.get('payModel')?.isAltOneClick?.();
    },

    /**
     * @public
     * @return {boolean}
     */
    discountBannerAllowed() {
        return this.get('discount') && !this.__inMethods([
            'paygarden',
        ]);
    },

    /**
     * @returns {boolean}
     * @private
     */
    __isMethodsWithDescriptor() {
        return this.__inMethods([
            PAYMENT_METHODS.CARD,
        ]);
    },

    /**
     * Font increase on PP for 50+
     * @return {Promise<boolean|*>}
     */
    async adultContentAvailable() {
        const { data } = await getClientInstance().query({ query: ADULT_CONTENT_AVAILABLE_QUERY });

        return Boolean(getCookie(ADULT_BIG_FONT_SIZE)) || data?.userFeatures?.isAdultContent;
    },

    /**
     * @param {Array} methods
     * @return {boolean}
     * @private
     */
    __inMethods(methods) {
        return includes(methods, this.__getMethod());
    },

    /**
     * @return {string || null}
     * @private
     */
    __getMethod() {
        return this.get('payModel') && this.get('payModel').get('method');
    },

    /**
     * Check if holiday banner is available for methodTab
     *
     * Don't use such method before calling "initHolidayService".
     *
     * @public
     * @returns {boolean}
     */
    isHolidayBannerAllowedByMethod: function () {
        let processors = window.app.holidayService.get('data').processors;

        if (!Array.isArray(processors)) {
            processors = [processors];
        }

        // Holiday banner is also and everytime available for different methods.
        return _.includes(processors, this.get('payModel').get('method'));
    },

    /**
     * @public
     * Refetch model from server
     */
    refetch: function() {
        this.set('ready', false);

        this.run({
            redirect: function () {
                return false;
            }
        });
    },

    getPayButtonText: function (allowedWalletMethods) {
        if (this.get('buySecurelyPayButton')) {
            return yiiT.t('paymentPage', 'button.buy_securely');
        }

        const isPaypalOneClick = this.isPayPalOneClick() && !this.get('isCardFormVisible');

        const payButtonText = this.get('payButtonText');

        if (isPaypalOneClick) {
            const paypalButtonTextKey =
              payButtonText?.paymentMethodButtonText[WALLET_METHODS.PAY_PAL_V2]?.oneClickButtonText;

            if (paypalButtonTextKey) {
                return getPageButtonTranslate(paypalButtonTextKey);
            }
        }

        const activeOriginalMethod = this.methodsCollection.getActiveOriginalMethod();

        const paymentMethodButtonText = payButtonText?.paymentMethodButtonText;

        if (
            !this.isCardMethod() &&
            paymentMethodButtonText?.[activeOriginalMethod]?.defaultButtonText
        ) {
            return getPageButtonTranslate(paymentMethodButtonText[activeOriginalMethod].defaultButtonText);
        }

        if (!this.isCardMethod() && this.__isWalletMethodsForAltAvailable()) {
            return getPageButtonTranslate('button.pay_now');
        }

        // Fallback for old API in case frontend will release sooner
        if (_.isString(payButtonText)) {
            return getPageButtonTranslate(payButtonText);
        }

        const {defaultButtonText} = getPayButtonTextSettings(payButtonText, allowedWalletMethods);

        // TODO(ilya.zub): payButtonText.payButtonPackageTextEntityMap[this.getSelectedPackage().get('package').id]
        return defaultButtonText ? getPageButtonTranslate(defaultButtonText) : getPageButtonTranslate('button.pay_now');
    },

    /**
     * @public
     * @returns {String|null}
     */
    getFormattedPhonePrefix: function () {
        const phonePrefixExample = this.get('phonePrefixExample');

        return phonePrefixExample ? `+${phonePrefixExample.full_prefix}` : null;
    },

    /**
     * @public
     * @return {boolean}
     */
    isCardHolderAllowed() {
        if (this.isOneClickWithCvv()) {
            return true;
        }

        return some(this.getLongFormFields(), {
            longFormField: CARD_HOLDER,
        });
    },

    /**
     * @public
     * @return {object}
     */
    getLongFormFields() {
        const longFormFields = this.get('longFormFields') || {};

        return longFormFields.longFormFields;
    },

    /**
     * @public
     * @return {array}
     */
    getAnonymousAutoFillFields() {
        const longFormFields = this.get('longFormFields') || {};

        return longFormFields.anonymousAutoFillFields;
    },

    /**
     * @public
     * @returns {Object}
     */
    getCurrentMethodSettings: function () {
        return result(this.get('altMethodsSettings'), this.methodsCollection.getActiveMethod(), {});
    },

    /**
     * Wallet allowed methods list getter
     * @return {Promise<[]>}
     */
    async getWalletAllowedMethods() {
        if ((!this.isCardPaymentType() && !await this.__isWalletMethodsForAltAvailable()) ||
          (this.isCardPaymentType() && !this.get('isCardFormVisible') && !this.get('cardWallet'))) {
            return [];
        }

        return getWalletAllowedMethods({
            altMethodsSettings: this.get('altMethodsSettings'),
            packages: this.get('packages'),
            withPaypal: this.isCardPaymentType() && !isMicroFeaturesUrl(window.location.pathname),
            activePackage: this.getSelectedPackage(),
        });
    },

    /**
     * @return {boolean}
     * @public
     */
    isExtraDay() {
        return includes(EXTRA_DAY_VIAS, this.get('via'));
    },

    /**
     * Data for track pay click
     * @return {{isFormHidden: (boolean), paymentForm: (string), isOneClickShown: boolean, actionId: (string), isFrontendValidationPassed: (boolean)}}
     */
    getPayTrackData(viewDate, isWalletPayAction) {
        const payModel = this.get('payModel');
        const hasPayModelErrors = payModel?.hasErrors();
        const buttonStatus = isWalletPayAction ? this.get('isWalletButtonDisabled') : this.get('isButtonDisabled');

        const sameProps = {
            action: this.actionId,
            paymentForm: payModel?.getFormName(),
            timeInterval: viewDate ? Math.floor((Date.now() - viewDate) / 1000) : 0,
        };


        if (!this.isCardPaymentType()) {
            const method = this.__getMethod();
            const scenario = this.get('altMethodsScenario')[method];
            const isOneClickScenario = PaymentScenario.ONECLICK === scenario;

            return {
                ...sameProps,
                isOneClickShown: isOneClickScenario,
                isFormHidden: isOneClickScenario,
                isFrontendValidationPassed: isOneClickScenario ? true : !hasPayModelErrors,
                isButtonActive: buttonStatus ? false : !this.getButtonLockCondition(),
            };
        }

        const isCardFormVisible = this.get('isCardFormVisible');
        const isPaypalOneClick = this.isPayPalOneClick();
        const isShowOneClick = this.get('isShowOneClick');

        return {
            ...sameProps,
            isOneClickShown: isShowOneClick || isPaypalOneClick,
            isFormHidden: !isCardFormVisible,
            isFrontendValidationPassed: isCardFormVisible ? !hasPayModelErrors : true,
            isButtonActive: !buttonStatus,
        };
    },

}), {
    /**
     * @static
     * @param {array} fields
     * @param {string} longFormField
     * @return {array}
     */
    filterLongFormFields(fields, longFormField) {
        const cardHolderField = find(fields, {
            longFormField,
        });

        return without(fields, cardHolderField);
    },
});
