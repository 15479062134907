import LoadOnDemandBehavior from 'components/application/LoadOnDemandBehavior';
import {find, values, includes, isEmpty, result, filter} from 'lodash';
import getCountryISO2 from 'country-iso-3-to-2';
import IBAN from 'iban';
import PaymentPagePackageCollection
    from 'app/mobSite/components/paymentPagePackage/models/PaymentPagePackageCollection';
import updatePaymentPageVisitsInCache from 'components/paymentPage/utils/updatePaymentPageVisitsInCache';
import PaymentPageUserModel from 'app/mobSite/components/paymentPage/models/PaymentPageUserModel';
import PaymentPagePayModelFactory from 'app/mobSite/components/paymentPage/PaymentPagePayModelFactory';
import keys from 'lodash/keys';
import isString from 'lodash/isString';
import some from 'lodash/some';
import isNull from 'lodash/isNull';
import compact from 'lodash/compact';
import without from 'lodash/without';

import PaymentScenario from '@core/payment/common/constants/paymentScenario';
import URI from '@core/utils/url';
import PAYMENT_ONECLICK_FLOWS from '@core/payment/common/constants/paymentOneclickFlows';
import * as MOTIVATION_TEMPLATE_TYPE from '@phoenix/payment/widgets/motivation/constants/templateType';
import isPaymentUrl from '@core/utils/url/isPayUrl';
import routeVar from '@core/graphql/vars/routeVar';
import logger from '@core/logger';
import {getActiveSplitGroup, SPLITS} from '@core/utils/split';
import {
    EXTRA_DAY_VIAS,
    VIA_WITH_SELECTED_PACKAGE,
} from '@core/payment/common/constants/vias';

import getPageButtonTranslate from 'app/components/paymentPage/utils/getPageButtonTranslate';
import {CARD_HOLDER} from '@core/payment/forms/card/constants/fieldNames';
import MethodsCollection from 'components/paymentPagePackage/models/MethodsCollection';
import getUser, {normalizeUserData} from 'components/paymentPage/utils/getUser';
import PAYMENT_METHODS, {
    WITH_SECOND_STEP_ALT_METHODS,
    IDEAL_METHODS,
    SEPA_METHODS,
    WALLET_METHODS,
    METHODS_WITHOUT_TAB,
} from '@core/payment/common/constants/paymentMethods';
import get from "lodash/get";
import getWalletAllowedMethods from 'app/components/paymentPage/utils/getWalletAllowedMethods';
import getPayButtonTextSettings from 'app/components/paymentPage/utils/getPayButtonTextSettings';
import getSelectedPackageDescriptionRule
    from 'app/components/paymentPageFooter/utils/getSelectedPackageDescriptionRule';
import {getClientInstance} from "@core/graphql/client";
import PackageTypes from '@core/payment/widgets/package/constants/packageTypes';
import ADULT_CONTENT_AVAILABLE_QUERY from '@phoenix/user/motivation/graphql/queries/adultContentAvailable.gql';
import CACHED_USER_QUERY from '@core/user/profile/target/graphql/queries/cachedUser.gql';
import isMicroFeaturesUrl from '@core/utils/url/isMicroFeaturesUrl';
import DEBIT_TYPES from "@core/payment/common/constants/debitTypes";
import getPaymentTemplate from '@core/payment/common/utils/getPaymentTemplate';
import PAYMENT_SOURCES from '@core/payment/common/constants/paymentSources';
import {
    HORIZONTAL_CAROUSEL,
    LIFE_TIME_OFFER,
    ONE_LINE_PP,
    MOB_UNIFY,
    ONE_STEP_CLEAR,
    ONE_STEP_CLEAR_FEATURES,
    ONE_STEP_CLEAR_2X,
    ONE_STEP_WITH_POLICY_AGREEMENT,
    TWO_STEP_WITH_POLICY_AGREEMENT
} from '@phoenix/payment/common/constants/templateType';
import getBootstrapParam from '@core/application/utils/getBootstrapParam';
import InAppBrowserName from '@core/application/constants/inAppBrowserName';
import {IN_APP_NAME} from '@core/application/constants/bootstrapParams';

import updateCachesAfterPayment, {isUpdating} from '@phoenix/payment/payProcess/utils/updateCachesAfterPayment';
import RESTRICTED_VIA_LIST from '@phoenix/payment/widgets/motivation/constants/restrictedViaList';
import {
    getCachedPurchasedPackageData,
    removeCachedPurchasedPackageData
} from '@core/payment/utils/cachePurchasedPackageData';
import PAYMENT_PAGE_DETAILS_QUERY from '@phoenix/payment/common/graphql/queries/paymentPageDetails.gql';
import {CUSTOM_FOOTER_LAYOUT} from '@phoenix/payment/common/constants/paymentPageDetails';
import getPriceWithCurrency from '@core/payment/common/utils/getPriceWithCurrency';
import ALLOWED_SEPA_NAMES from '@core/payment/common/constants/allowedSepaNames';
import calculateTotalAmount from '@phoenix/payment/common/utils/calculateTotalAmount';
import isCardPaymentType from '@core/payment/forms/card/utils/isCardPaymentType';
import {ACCOUNT_AND_BANK, EXTRA_TAB_NAME} from 'components/paymentPage/constants/sepaTabs';
import getCorrectPaymentScene from 'components/paymentPage/utils/getCorrectPaymentScene';
import defaultController from 'components/cache/defaultController';
import {getCookie} from '@core/utils/cookie';
import clearUrl from '@core/utils/url/clearUrl';
import DECLINE_VIA from '@core/payment/common/constants/declineVia';
import {localStorage} from '@core/utils/storage/storage';
import {ADULT_BIG_FONT_SIZE} from '@core/payment/common/constants/cookies';
import {LONG_FORM_FIELDS} from '@core/payment/common/constants/localStorageKeys';
import PaymentPageSuccessOrders from '@core/payment/payProcess/utils/PaymentPageSuccessOrders';
import {ViaEnum} from '@core/types/graphql';
import PAYMENT_ACTIONS from '@core/payment/common/constants/paymentActions';
import {EXTRA_DISCOUNT} from '@phoenix/payment/widgets/motivation/constants/templateType';
/**
 * @type {string}
 */
const FEE_PACKAGE_TYPE = 'fee';

/**
 * Via`s for redirect to PP on click admin message button
 * @private
 * @const {array}
 */
const ADMIN_READ_VIAS = [ViaEnum.chat_read_15_a, ViaEnum.chat_read_14_a];

/**
 * Via for redirect to PP on click admin message button
 * @private
 * @const {string}
 */
const ADMIN_EXTRA_FEATURE_VIA = ViaEnum.adminExtraFeature;

/**
 * Via`s for redirect to PP on click  user like or add favorite buttons
 * @type {(string)[]}
 */
const ADD_ACTION_VIAS = [ViaEnum.add_like, ViaEnum.add_favor];

/**
 * Time constant for one second
 * @constant {number}
 */
const MS_IN_SECOND = 1000;

/**
 * Time constant for one day
 * @constant {number}
 */
const MS_IN_DAY = 86400000; // 24 * 3600 * 1000

/**
 *
 * @param {string|PayButtonText} payButtonText
 * @param {array?} allowedWalletMethods
 * @param {Object?} params
 * @return {string}
 */
export const getPayButtonText = (payButtonText, allowedWalletMethods, params) => {
    // Fallback for old API in case frontend will release sooner
    if (isString(payButtonText)) {
        return getPageButtonTranslate(payButtonText);
    }

    const {defaultButtonText} = getPayButtonTextSettings(payButtonText, allowedWalletMethods);

    // TODO(ilya.zub): payButtonText.payButtonPackageTextEntityMap[this.getSelectedPackage().get('package').id]
    return defaultButtonText ? getPageButtonTranslate(defaultButtonText, params) : getPageButtonTranslate('button.pay_now');
};

/**
 * @class PaymentPageModel
 * @see BaseModel
 */
const PaymentPageModel = BaseModel.extend(_.extend({}, LoadOnDemandBehavior, {
    // TODO: when this vias stop sending to the second step remove it from constant
    PAID_FUNNEL_VIA: [
        ViaEnum.paidfunnel_m,
        ViaEnum.rm_popup,
        ViaEnum.paidfunnel_utm_sub_paid,
        ViaEnum.paidfunnel_holiday,
        ViaEnum.paidfunnel_utm_sub_pp,
        ViaEnum.paidfunnel_utm_sub_reqpaid3d,
        ViaEnum.paidfunnel_utm_sub_skpp3d,
    ],

    /**
     * This set of 'via' shows a payment method is forbidden
     * @type {array}
     */
    METHOD_FORBIDDEN_VIA: [
        ViaEnum.sepa_off,
    ],

    /**
     * @const {string}
     */
    FEATURES: 'features',

    /**
     * @const {number}
     */
    TIME_CORRECTION: 1000,

    XSALE_PACKAGE_ID: '1',

    /**
     * Need to cut text on sepa success page
     * @type {Array}
     */
    SEPA_METHODS_NAME: [
        ...Object.values(IDEAL_METHODS),
        ...Object.values(SEPA_METHODS),
    ],

    /**
     * @const {Array.<string>}
     */
    EXTERNAL_PAYMENT_METHODS: [
        'sofort',
        'wallet',
    ],

    /**
     * @const {string}
     */
    PACKAGE_ID_DELIMITER: '_',

    urlRoot: '/pay/membership/isJsonMode/true',

    defaults: {
        /**
         * @type {array|null} list of selected additional packages
         */
        selectedAdditionalPackages: null,

        /** true, if we have remembered card data and don't show card form */
        isCardFormVisible: true,
        /**  Array of focused fields. */
        focusedFields: [],
        payModel: null,

        /**
         * A redirect path after payment
         * @type {String}
         */
        returnPath: '',

        /**
         * A redirect path after successful payment if exists
         * @type {String}
         */
        successReturnPath: '',

        /**
         * @type {string}
         */
        extraTabName: ACCOUNT_AND_BANK,

        /**
         * flag to load topFeatures
         * if wee need it
         * @type {boolean}
         */
        loadTopFeatures: true,

        /**
         * Indicates that if we can show fail form or hide it
         * @type {boolean}
         */
        showFailForm: true,

        /**
         * collection of loaded
         * top benefits from directive
         * @type {object}
         */
        topBenefits: {},

        /**
         * Number of pay page visit.
         */
        pageVisits: 0,

        /**
         * prevent load user data from search and appData for reqpay vias
         * because if user have decline pay, via goes change to decline type,
         * so we can't check restrictions by via and we must check by param from server
         */
        withoutUserData: false,

        /**
         * We can get new long form fields after decline and we need to init validation
         * rules one more time.
         * @var {boolean}
         */
        isNeededChangeOfValidationRules: false,

        /**
         * Param for multi microfeature pay
         * @type {array|null}
         */
        additionalProducts: null,

        /**
         * @type {boolean}
         */
        oneStepPageAllowed: false,

        /**
         * @type {boolean}
         */
        isClickableElementsEnabled: false,

        /**
         * Flag of additional pay confirmation checkbox status, only for card payment
         * @type {boolean}
         */
        additionalTermsChecked: null,

        /**
         * Flag for show error if billing policy unchecked
         * @type {boolean}
         */
        billingPolicyConfirmed: true,

        /**
         * Flag of auto renewal agree
         * @type {boolean}
         */
        autoRenewalAgreeChecked: null,

        /**
         * Flag of additional pay confirmation checkbox status, only for credits
         * @type {boolean}
         */
        credentialsPolicyChecked: false,

        /**
         * Custom payment page details.
         */
        paymentPageDetails: [],

        isInstantRepeat: null,

        /**
         * Flag for check if card payment form is touched
         */
        formIsTouched: false,

        /**
         * Payment source. Need for calculate payment visits correctly.
         * For 'popup' source payment visits not increments.
         */
        source: PAYMENT_SOURCES.PAYMENT_PAGE,

        isButtonDisabled: false,

        /**
         * separate status for wallet button for
         * correct work in split international.20220919.ostapenko.BU_173502
         * @type {boolean}
         */
        isWalletButtonDisabled: false,
    },

    /**
     * point on current payment type
     */
    actionId: PAYMENT_ACTIONS.MEMBERSHIP,

    /**
     * Remove onclick from html
     * @param data
     * @returns {object}
     */
    parse: function(data) {
        _.each(data.smsInstructions, function(instructions, name, list) {
            list[name] = this._removeOnclick(instructions);
        }.bind(this));

        if (data.paymentFooter) {
            data.paymentFooter = this._removeOnclick(data.paymentFooter);
        }

        if (data.user) {
            data.user = PaymentPageUserModel.fromPaymentModel(data.user);
        }

        if (data.extraHourTimer) {
            data.extraHourTimer *= this.TIME_CORRECTION;
        }

        if (data.motivationTemplate && !Object.values(MOTIVATION_TEMPLATE_TYPE).includes(data.motivationTemplate)) {
            data.motivationTemplate = null;
        }

        var baseDiscount = data.discount ? data.discount.percent : 0;

        this.methodsCollection.removeNotReceived(keys(data.packages));

        const currentTab = this.methodsCollection.getActiveMethod();
        const currentDefaultTab = this.get('defaultActiveTab');
        const defaultActiveTab = data.defaultActiveTab;

        /**
         * Change active tab to default tab after decline if default tab is changed
         * @type {boolean}
         */
        const needUpdateDefaultTabAfterDecline = this.isDeclinedPay() &&
          Boolean(currentDefaultTab) &&
          Boolean(currentTab) &&
          (currentTab !== defaultActiveTab) &&
          (currentDefaultTab !== defaultActiveTab);

        if (needUpdateDefaultTabAfterDecline) {
            this.returnToPrevStepAndSetActiveTab(defaultActiveTab);
        } else if (!this.methodsCollection.getActive() && this.get('defaultActiveTab') !== defaultActiveTab) {
            // Default active tab disappeared from new server response
            this.set('defaultActiveTab', defaultActiveTab);
            this.setDefaultMethodTab();
        }

        _.each(data.packages, function (packages, method) {
            var methodPackages = _.values(packages);
            var baseAmount = this.__getBaseAmountValue(methodPackages);

            /**
             * Check if any method is cloned, and if it is in the list of clones, we take it
             * @type {string}
             */
            let methodName = method;
            if (data.tabPaymentForm) {
                methodName = data.tabPaymentForm[method] || methodName;
            }

            // Populating the collection of available methods
            if (this.canShowTabForPaymentMethod({ method, altMethodsScenario: data.altMethodsScenario })) {
                this.methodsCollection.addNew({
                    method: methodName,
                    originalMethod: method,
                });
            }

            _.each(methodPackages, _.bind(function(currentPackage) {
                currentPackage.baseAmount = baseAmount;
                currentPackage.baseDiscount = baseDiscount;
                currentPackage.isBasePricePackage = this.__getPackagePerDayPrice(currentPackage) == baseAmount;
                // Method name which may not contain the original name, since used when cloning a method
                currentPackage.method = methodName;
                // Original unique method name
                currentPackage.originalMethod = method;
                currentPackage.packageId = currentPackage.id;
                currentPackage.id = currentPackage.id + this.PACKAGE_ID_DELIMITER + currentPackage.originalMethod;
            }, this));
        }.bind(this));

        const longFromFieldsForTestOnly = localStorage.getItem(LONG_FORM_FIELDS);

        if (longFromFieldsForTestOnly) {
            data.longFormFields = {
                longFormFields: longFromFieldsForTestOnly.longFormFields || [],
                anonymousAutoFillFields: longFromFieldsForTestOnly.anonymousAutoFillFields || [],
            };
        }

        if (this.get('payModel')?.get('changingPaymentDetails')) {
            data.altMethodsScenario[this.get('payModel').get('method')] = PaymentScenario.INITIAL;
        }

        return data;
    },

    /**
     * @public
     * @return {MethodsCollection}
     */
    getMethodsCollection() {
        return this.methodsCollection;
    },

    /**
     * Check if selected method can display tab
     * @public
     * @return {Boolean}
     */
    canShowTabForPaymentMethod({ method, altMethodsScenario }) {
        if (includes(METHODS_WITHOUT_TAB, method)) {
            return false;
        }

        if (method === PAYMENT_METHODS.CCBILL) {
            return this.isOneClickByScenario(altMethodsScenario?.[PAYMENT_METHODS.CCBILL]);
        }

        return true;
    },

    /**
     * Partial check for the availability of the PayPal one click payment functionality.
     * For full one click availability need use this.isPayPalOneClick
     * @public
     * @return {Boolean}
     */
    isAllowedPayPalOneClick() {
        const settings = this.get('altMethodsSettings')?.[WALLET_METHODS.PAY_PAL_V2];
        const scenario = this.get('altMethodsScenario')?.[WALLET_METHODS.PAY_PAL_V2];

        if (!settings || !scenario) {
            return false;
        }

        return settings.isOneClickForm && this.isOneClickByScenario(scenario);
    },

    /**
     * @param {String} scenario
     * @return {Boolean}
     * @public
     */
    isOneClickByScenario: function (scenario) {
        return scenario === PaymentScenario.ONECLICK;
    },

    /**
     * @param {String}
     * @return {Boolean}
     * @public
     */
    isTemplateWithPolicyAgreement: function () {
        return [ONE_STEP_WITH_POLICY_AGREEMENT, TWO_STEP_WITH_POLICY_AGREEMENT].includes(getPaymentTemplate(this.get('templateSettings')?.template));
    },

    /**
     * @param {String}
     * @return {Boolean}
     * @public
     */
    isOneStepWithPolicyAgreement: function () {
        return ONE_STEP_WITH_POLICY_AGREEMENT === this.get('templateSettings')?.template;
    },

    /**
     * Sepa mandate masked fields for current method
     * @public
     * @return {Object|null}
     */
    getSepaMandateFields() {
        return this.get('altMethodsSettings')?.[this.getMethodsCollection().getActiveMethod()]?.maskedFields || null;
    },

    /**
     * Check if is allowed PayPal one click.
     * Allowed only for card payment method.
     * @public
     * @return {Boolean}
     */
    isPayPalOneClick() {
        return this.isAllowedPayPalOneClick() && this.isCardPaymentType();
    },

    /**
     * return isStrictLegalMode
     */
    getIsStrictLegalMode() {
        const footer = this.get('footer') || {};

        return get(footer, 'displaySettingData.isStrictLegalMode', false);
    },

    /**
     * @see PaymentPageMotivationBannerView
     * @return {boolean}
     * @public
     */
    isSpecialPackagesVia: function () {
        return _.includes(RESTRICTED_VIA_LIST, this.get('via'));
    },

    /**
     * @return {boolean}
     * @public
     */
    isDeclinedPay: function () {
        return _.includes(DECLINE_VIA, this.get('via'));
    },

    /**
     * @return {boolean}
     * @public
     */
    isCurrentMethodForbidden: function () {
        return _.includes(this.METHOD_FORBIDDEN_VIA, this.get('via'));
    },

    /**
     * is need to mark checkbox before pay
     * @return {boolean}
     */
    isAdditionalTermsNeeded() {
        const additionalTermsStatusMap = this.get('additionalTermsStatusMap');
        const billingPolicy = additionalTermsStatusMap?.billingPolicy;
        const agreeAndContinue = additionalTermsStatusMap?.agreeAndContinue;

        const isAdditionalTerms = ((billingPolicy !== undefined || agreeAndContinue !== undefined)) &&
          this.isCardPaymentType() && isPaymentUrl(location.pathname);

        const isWithBillingPolicy = getSelectedPackageDescriptionRule({
            footerParts: this.getFooterParts(),
            packageId: this.getSelectedPackage()?.get('package').id,
        })?.isWithBillingPolicy;

        if (isAdditionalTerms && isWithBillingPolicy) {
            logger.sendWarning(
                `[PaymentPageModel] Footer part "PackageDescriptionWithBillingPolicy" doesn't support additional terms "billingPolicy" and "agreeAndContinue"`
            );

            return false;
        }

        return isAdditionalTerms && !isWithBillingPolicy;
    },

    /**
     * is need to mark checkbox autoRenewalAgree before pay
     * @return {boolean}
     */
    isAutoRenewalAgreeNeeded() {
        const additionalTermsStatusMap = this.get('additionalTermsStatusMap');
        return this.isDefaultCardPaymentType() && (additionalTermsStatusMap?.autoRenewalAgree !== undefined);
    },

    getPackageDescriptionCheckboxDataName() {
        return this.isAutoRenewalAgreeNeeded() ? 'autoRenewalAgreeChecked': '';
    },

    /**
     * is need to mark checkbox before pay for Credit
     * @returns {boolean}
     */
    isCredentialsPolicyNeeded() {
        const billingPolicyData = this.getFooterParts().TextBillingPolicy;

        return !!(billingPolicyData && billingPolicyData.isShowCredentialsPolicy && this.isCardPaymentType() &&
          isPaymentUrl(location.pathname));
    },

    /**
     * is need to mark checkbox before pay
     * @returns {boolean}
     */
    isAdditionalTermsUpdated() {
        return this.isAdditionalTermsNeeded() && includes(this.get('additionalTerms'), 'autoRenewalAgreeXSale');
    },

    /**
     * is card pay button locked due unchecked additional terms or credentialsPolicy
     * @returns {boolean}
     */
    isCardPayButtonLocked() {
        if (!this.isCardPaymentType()) {
            return false;
        }

        return (this.isAdditionalTermsNeeded() && !this.get('additionalTermsChecked')) ||
          (this.isCredentialsPolicyNeeded() && !this.get('credentialsPolicyChecked'));
    },

    /**
     * @public
     * @return {Boolean}
     */
    isAllowedCreditAutofill() {
        return false;
    },

    /**
     * Get the number of days since first payment page visit
     * @returns {Number}
     */
    daysAfterPaymentVisit() {
       return Math.floor(
         ((new Date().getTime() - this.get('firstPaymentPageEnterTime') * MS_IN_SECOND) / MS_IN_DAY) + 1
       );
    },

    /**
     * get most expensive per day price
     * @param packages
     * @return {number}
     * @private
     */
    __getBaseAmountValue: function (packages) {
        var basePackage = _.maxBy(packages, function (currentPackage) {
            return this.__getPackagePerDayPrice(currentPackage);
        }.bind(this));

        if (!basePackage || !basePackage.price) {
            return 0;
        }

        return this.__getPackagePerDayPrice(basePackage);
    },

    /**
     * @param currentPackage
     * @return {number}
     * @private
     */
    __getPackagePerDayPrice: function (currentPackage) {
        if (currentPackage.package.type === 'D' && currentPackage.price.per_period) {
            return Number(currentPackage.price.per_period.amount);
        }

        return Number(currentPackage.price.amount / currentPackage.package.interval);
    },

    /**
     * @param html
     * @returns {string}
     * @private
     */
    _removeOnclick: function(html) {
        return html.replace(/(onclick="((?:\\"|[^"])*)")|(onclick='((?:\\'|[^'])*)')/g, '');
    },

    /**
     * Get iban object according to country code
     * @param country {string}
     * @return {Specification} - IBAN Object
     */
    getIban: function(country) {
        let countryCode = '';

        if (country) {
            countryCode = country.replace(/\s/g,'').slice(0, 2);
        } else {
            countryCode = this.get('country_code').toUpperCase();
        }

        if (countryCode.length > 2) {
            countryCode = getCountryISO2(countryCode);
        }

        return IBAN.countries[countryCode];
    },

    /**
     * @return {number}
     * @public
     */
    getDiscount: function() {
        const method = this.getMethodsCollection().getActiveMethod();
        const altMethodDiscount = this.get('altMethodDiscounts')?.[method]?.percent;
        let discount = this.get('discount')?.percent || 0;

        if (!this.isDefaultCardPaymentType()) {
            discount = altMethodDiscount ? altMethodDiscount : discount;
        }

        return discount;
    },

    /**
     * Need placeholders for SEPA form
     * @return {boolean}
     */
    isSepaFormStyled: function() {
        return this.get('templateSettings')?.sepaFormStyle;
    },


    /**
     * Get postal code of user
     * @return {string}
     */
    getPostalCode: function() {
        const postalCode = this.get('userAttributes');

        return (postalCode && postalCode.postalCode) || yiiT.t('paymentPageCommDoo', 'text.sepaPostalCode');
    },

    url() {
        const uri = URI(this.urlRoot);
        uri.addSearch('via', this.get('via'));

        if (!this.get('via')) {
            logger.sendWarning('[PaymentPageModel] There is no via in request');
        }

        const viaProfileId = this.get('viaProfileId');
        const prevVia = this.get('prevVia');

        if (viaProfileId) {
            uri.addSearch('viaProfileId', viaProfileId);
        }
        if (prevVia) {
            uri.addSearch('prevVia', prevVia);
        }

        const packageId = this.getSelectedPackage()?.get('packageId');

        if (packageId) {
            uri.addSearch('packageId', packageId);
        }

        const source = this.get('source');

        if (source) {
            uri.addSearch('source', source);
        }

        return uri.toString();
    },

    initialize() {
        this.set('inAppBrowser', getBootstrapParam(IN_APP_NAME) !== InAppBrowserName.NORMAL_BROWSER);

        getClientInstance().query({ query: PAYMENT_PAGE_DETAILS_QUERY }).then(({ data }) => {
            this.set('paymentPageDetails', data?.payment.paymentPageDetails || []);
        });

        getActiveSplitGroup(
          SPLITS.BUY_SECURELY_PAY_BUTTON.ID,
          SPLITS.BUY_SECURELY_PAY_BUTTON.GROUP.ACTIVE
        ).then(result => {
            if (result && this.isMembershipStage()) {
                this.set('buySecurelyPayButton', true);
            }
        });

        this.collection = new PaymentPagePackageCollection();
        this.successOrdersModel = PaymentPageSuccessOrders;
        this.methodsCollection = new MethodsCollection();

        if (!this.get('viaProfileId')) {
            this.getUser();
        }

        this.once('change:viaMethod', this.setPackageFromCache);
        this.once('sync', this.onSyncOnce);
        this.once('ready', updatePaymentPageVisitsInCache);
        this.on('sync', this.onSync);
        this.once('sync', this.initSelectedAdditionalPackages);
        this.on('request', this.__validateRequestVia);
        this.on('change:isCardFormVisible', this.fillCardScenario);
        this.on('change:isShowOneClick', this.onChangeOneClick);
        this.listenTo(this.methodsCollection, 'activeMethodSet', this.updatePayModel);
        this.listenTo(this.methodsCollection, 'activeMethodSet', () => this.initSelectedAdditionalPackages());
        this.on('pay:success', this.__onSuccess);
        this.on('pay:fail', this.__onFail);
        this.on('pay:cancel', () => this.__stopProcessing());
        this.on('change:formIsTouched', this.fillCardScenario);
        this.on('change:longFormFields', this.__handleLongFormFieldsChange);
        this.on('change:stockId', this.onChangeStockId);

        this.listenTo(this.collection, 'change:is_active', this._onActivePackageChange);

        this.onPostMessageHandler = this.onPostMessage.bind(this);
        window.addEventListener('message', this.onPostMessageHandler);
        this.listenTo(this, 'remove', this.stopListenPostMessage.bind(this));
    },

    stopListenPostMessage() {
        window.removeEventListener('message', this.onPostMessageHandler);
    },

    onPostMessage(message) {
        const redirectUrl = message?.data?.externalPaymentInAnotherWindow?.redirectUrl;

        if (redirectUrl) {
            const { via, orderId } = URI(redirectUrl).search(true);
            const isDecline = DECLINE_VIA.includes(via);

            if (isDecline) {
                this.trigger('pay:fail', {
                    failPageLogic: via,
                });
            } else {
                if (orderId) {
                    PaymentPageSuccessOrders.add(orderId, via);
                }

                window.app.router.navigate(clearUrl(redirectUrl), {
                    trigger: true,
                });
                updateCachesAfterPayment();
                this.stopListenPostMessage();
            }
        }
    },

    /**
     * Cache the selected package when the user navigates to external payment site
     * and restore the selected package if the user returns.
     * @see cachePurchasedPackageData.js
     * @private
     */
    setPackageFromCache() {
        const cachedPurchasedPackageData = getCachedPurchasedPackageData();

        if (!cachedPurchasedPackageData) {
            return;
        }

        const { method, packageId } = cachedPurchasedPackageData;

        const viaMethod = this.get('viaMethod');

        if (viaMethod === method && packageId) {
            this.setActivePackage(packageId);
        }

        removeCachedPurchasedPackageData();
    },

    isBillingPolicyWithPackageDescription() {
        return this.isMembershipStage() &&
            this.get('paymentPageDetails').includes(CUSTOM_FOOTER_LAYOUT);
    },

    /**
     * @public
     * @param {string} additionalPackageType
     * @param {boolean} isSelected
     */
    setSelectedAdditionalPackage(additionalPackageType, isSelected) {
        let selectedAdditionalPackages = compact([].concat(this.get('selectedAdditionalPackages')));

        if (isSelected) {
            if (!selectedAdditionalPackages.includes(additionalPackageType)) {
                selectedAdditionalPackages.push(additionalPackageType);
            }
        } else {
            selectedAdditionalPackages = without(selectedAdditionalPackages, additionalPackageType);
        }

        this.set('selectedAdditionalPackages', selectedAdditionalPackages);
    },

    /**
     * @public
     * @return {boolean}
     */
    isGuaranteesAllowed() {
        const activeMethod = this.getMethodsCollection().getActive();

        return activeMethod && ALLOWED_SEPA_NAMES.includes(activeMethod.get('method'));
    },

    onSyncOnce: function() {
        this.setDefaultMethodTab();
        this.__validateModelVia();
    },

    initSelectedAdditionalPackages(skip) {
        const isOneStepClearTemplate = this.isOneStepClearTemplate();

        if (
            (isOneStepClearTemplate && skip) ||
            !this.isAdditionalPackagesAvailable() ||
            !isNull(this.get('selectedAdditionalPackages'))
        ) {
            return;
        }

        const packageList = this.getAllAdditionalPackages(
            this.getMethodsCollection().getActiveMethod(),
            this.getSelectedPackage().get('package').id
        );

        packageList.forEach((packageItem, index) => {
            const {
                isCheckedByDefault,
                isXsaleCheckBoxLogicInverted,
                additionalPackageType,
            } = packageItem;

            if (this.isAdditionalPackagesAvailable(additionalPackageType)) {
                const isFirst = index === 0;
                const isXsaleInvertedLogic = isXsaleCheckBoxLogicInverted ? !isCheckedByDefault : isCheckedByDefault;

                this.setSelectedAdditionalPackage(
                    additionalPackageType,
                    /**
                     * For oneStepClear/oneStepClearFeatures should always select the first package.
                     * TODO should move this logic to server after https://jira.togethernetworks.com/browse/BU-187225
                     * At the moment the backend is unable to preset a specific package due to spike
                     */
                    isCheckedByDefault && [ ONE_STEP_CLEAR, ONE_STEP_CLEAR_FEATURES ].includes(
                    getPaymentTemplate(this.get('templateSettings')?.template)) ? isFirst : isXsaleInvertedLogic
                );
            }
        });
    },

    /**
     * @param {object} data
     * @private
     */
    __onSuccess: function (data) {
        /* No need change stop processing state because page redirect to external resource on the same browser tab */
        if (data.model && this.__isExternalPaymentMethod(data.model.get('method'))) {
            return;
        }

        this.__stopProcessing();
    },

    /**
     * Handle PayModel fail.
     * Set prevVia as current via except second failed payment to avoid via=inpage_decline&prevVia=inpage_decline.
     * @private
     */
    __onFail(data) {
        // No failPageLogic flag in server answer means that is no fail pay attempt, validation error for example.
        if (!data.failPageLogic) {
            this.__stopProcessing();
            return;
        }

        const via = this.get('via');
        const prevVia = this.get('prevVia');
        const prevFailed = DECLINE_VIA.some(failVia => failVia === via);

        this.set({
            prevVia: prevFailed ? prevVia : via,
            via: data.failPageLogic,
            showFailForm: true,
        });

        this.__stopProcessing();
    },

    /**
     * @param {string} method
     * @return {boolean}
     * @private
     */
    __isExternalPaymentMethod: function (method) {
        return _.includes(this.EXTERNAL_PAYMENT_METHODS, method);
    },

    /**
     * Set stop processing state
     * @private
     */
    __stopProcessing: function () {
        this.set({
            isPaymentProcessing: false
        });
    },

    /**
     * Set stop processing state
     * @param {boolean} isProcessing
     */
    togglePaymentProcessing: function (isProcessing) {
        isProcessing = isProcessing === undefined ? !this.get('isPaymentProcessing') : isProcessing;
        this.set('isPaymentProcessing', isProcessing);
    },

    /**
     * @public
     * @return {Boolean}
     */
    getButtonLockCondition() {
        return this.getButtonProcessingCondition() || this.isCardPayButtonLocked();
    },

    getButtonProcessingCondition() {
        return this.get('isPaymentProcessing') || this.get('loading') || isUpdating();
    },

    /**
     * @returns {Boolean}
     * @public
     */
    isPayMethodSepa: function() {
        return _.includes(this.SEPA_METHODS_NAME, this.get('viaMethod'));
    },

    /**
     * Check if is allowed one click payment with opened card form
     * @return {boolean}
     * @protected
     */
    isOneClickPaymentWithOpenedCardForm() {
        return this.get('isShowOneClick') && this.get('isCardFormVisible') &&
          !this.isPayPalOneClick() &&
          !this.isOneClickWithCvvAvailable() &&
          !this.get('formIsTouched');
    },

    isOneClickWithCvv() {
        return this.isOneClickWithCvvAvailable() && !this.get('isCardFormVisible');
    },

    isOneClickWithCvvAvailable() {
        return this.get('isShowOneClick') &&
            this.get('paymentSystems')?.card?.oneClickFlow === PAYMENT_ONECLICK_FLOWS.USE_CVV;
    },

    isOneClickSimpleAvailable() {
        return this.get('isShowOneClick') &&
            this.get('paymentSystems')?.card?.oneClickFlow === PAYMENT_ONECLICK_FLOWS.SIMPLE;
    },

    /**
     * @return {number}
     * @protected
     */
    isHidePaymentForm() {
        if (this.isOneClickPaymentWithOpenedCardForm()) {
            return 1;
        }

        return Number(!this.get('isCardFormVisible'));
    },

    /**
     * Get payment scenario
     * @return {string|string}
     * @protected
     */
    getCardPaymentScenario() {
        if (this.isOneClickPaymentWithOpenedCardForm()) {
            return PaymentScenario.ONECLICK;
        }

        return (this.get('isCardFormVisible') || this.get('cardWallet')) ? PaymentScenario.INITIAL :
            PaymentScenario.ONECLICK;
    },

    /**
     * update card payment model scenario, according card form visibility
     * @private
     */
    fillCardScenario: function() {
        if (!this.get('payModel')) {
            return;
        }

        if (this.isCardPaymentType()) {
            this.get('payModel').set('scenario', this.getCardPaymentScenario());
            this.get('payModel').setScenario(this.getCardPaymentScenario());
        }
    },

    onChangeOneClick: function(model, isShowOneClick) {
        const disableToggleCardFormVisible = this.get('enterCardFirst');
        // Disable change isCardFormVisible by isShowOneClick for split.
        // isCardFormVisible set when click pay or skip button.
        // @ see frontend/app/mobSite/components/paymentPage/views/PaymentPageCardFormView.js
        // onOutSideActionPayClick or onOutSideActionSkipClick
        if (disableToggleCardFormVisible) {
            this.toggleCardFormVisible(true);
            return;
        }

        /**
         * Set card form is hidden, when PayPal one click is allowed.
         */
        if (this.isAllowedPayPalOneClick() && this.get('defaultActiveTab') === PAYMENT_METHODS.CARD) {
            this.toggleCardFormVisible(false);
            return;
        }

        this.toggleCardFormVisible(!isShowOneClick);
    },

    isTwoStepPaymentPage() {
        return [ HORIZONTAL_CAROUSEL, MOB_UNIFY, LIFE_TIME_OFFER, TWO_STEP_WITH_POLICY_AGREEMENT ]
            .includes(
                getPaymentTemplate(this.get('templateSettings')?.template)
            );
    },

    returnToPrevStepAndSetActiveTab(defaultActiveTab) {
        this.set('defaultActiveTab', defaultActiveTab);

        if (this.isTwoStepPaymentPage()) {
            const payUrl = URI(window.location.pathname + window.location.search);

            if (payUrl.hasSearch('stockId')) {
                /**
                 * Skip hide fail form
                 * @see this.onChangeStockId
                 */
                this.skipHideFailForm = true;

                payUrl.removeSearch('stockId');
            }

            if (payUrl.hasSearch('viaMethod')) {
                payUrl.removeSearch('viaMethod');
            }

            if (payUrl.hasSearch('isPaid')) {
                payUrl.removeSearch('isPaid');
            }

            window.app.router.navigate(payUrl.toString(), { replace: true, trigger: true });
        }

        this.setDefaultMethodTab(true);
    },

    onChangeStockId: function (model, stockId) {
        const isTwoStepPaymentPage = this.isTwoStepPaymentPage();

        if (!this.skipHideFailForm && !stockId && this.isDeclinedPay() &&
          isTwoStepPaymentPage && this.get('showFailForm')) {
            this.set('showFailForm', false);
        }

        if (this.skipHideFailForm) {
            this.skipHideFailForm = false;
        }
    },

    /**
     * @param {boolean} isNeedShow
     * @public
     */
    toggleCardFormVisible: function (isNeedShow) {
        isNeedShow = isNeedShow === undefined ? !this.get('isCardFormVisible') : isNeedShow;
        this.set('isCardFormVisible', isNeedShow);
    },

    onSync: function() {
        this.__processAdditionalTermsStatusMap();

        this.processCollection();
        this.updatePayModel();
    },

    /**
     * Definition is checked autoRenewalAgree and billingPolicy checkboxes
     * @private
     */
    __processAdditionalTermsStatusMap() {
        const {
            billingPolicy,
            agreeAndContinue,
            autoRenewalAgree,
            credentialsPolicy,
        } = this.get('additionalTermsStatusMap') || {};

        let billingPolicyDefaultValue = null;

        if (billingPolicy !== undefined) {
            billingPolicyDefaultValue = billingPolicy;
        }

        if (agreeAndContinue !== undefined) {
            billingPolicyDefaultValue = agreeAndContinue;
        }

        if (billingPolicyDefaultValue !== null) {
            this.set('additionalTermsChecked', billingPolicyDefaultValue);
        }

        if (autoRenewalAgree !== undefined && !this.get('autoRenewalAgreeChecked')) {
            this.set('autoRenewalAgreeChecked', autoRenewalAgree);
        }

        if (this.isCredentialsPolicyNeeded()) {
            this.set('credentialsPolicyChecked', credentialsPolicy);
        }
    },

    getUserFromCache() {
        const viaProfileId = this.get('viaProfileId');

        if (viaProfileId) {
            const client = getClientInstance();

            const userData = client.readQuery({
                query: CACHED_USER_QUERY,
                variables: { userId: viaProfileId }
            });

            if (userData) {
                let user = normalizeUserData(userData.user);
                user = new PaymentPageUserModel(user);
                this.set('user', user);
                return user;
            }
        }

        return this.get('user');
    },

    /**
     * if current user not the one from which we came to payment page, return it.
     * return random user from search otherwise
     * @return {Promise}
     */
    getUser: function () {
        return getUser({
            viaProfileId: this.get('viaProfileId'),
            isUserMotivation: this.isUserMotivation(),
            userFromModel: this.get('user'),
            query: CACHED_USER_QUERY,
            PaymentPageUserModel,
        }).then(user => {
            this.set('user', user);
            return user;
        });
    },

    isUserMotivation: function () {
        const isUserMotivation = !!this.get('viaProfileId');

        if (isUserMotivation && !this.get('user')) {
            logger.sendInfo("There is no user model for via = " + this.get('via'));

            return false;
        }

        return isUserMotivation;
    },

    /**
     * Create pay model by params
     * @param {boolean} clearPayModel
     * @param {object} params
     * @returns {*}
     */
    createPayModelFromFactory: function (clearPayModel, params) {
        return PaymentPagePayModelFactory.getInstance().getModel(clearPayModel, params);
    },

    /**
     * @param {boolean} clearPayModel
     */
    updatePayModel: function(clearPayModel) {
        const longFormFields = this.get('longFormFields') || {};
        // Remember previous data when payment is declined
        const { debitType, financialServicePlatform, bin, cardTypeLogo } = this.get('payModel')?.get('currentBinEntity') || {};
        const processingFeeBannerData = this.get('payModel')?.get('processingFeeBannerData') || this.get('processingFeeBannerData');
        const isEmptyFormAfterDecline = this.get('payModel')?.get('isEmptyFormAfterDecline');

        this.set('payModel', this.createPayModelFromFactory(this.isCardPaymentType() || clearPayModel, {
            method: this.methodsCollection.getActiveMethod(),
            via: this.getCurrentPaymentStage(),
            altMethodsScenario: this.get('altMethodsScenario'),
            altMethodsSettings: this.get('altMethodsSettings'),
            additionalFields: longFormFields.longFormFields,
            sepaAutofillAccess: this.get('sepaAutofillAccess'),
            isInstantRepeat: this.get('isInstantRepeat'),
            sepaMandateFields: this.getSepaMandateFields(),
            formattedPhonePrefix: this.getFormattedPhonePrefix(),
            additionalProducts: this.get('additionalProducts'),
            isAccountAndBank: this.isAccountAndBank(),
            country_code: this.get('country_code'),
            oneLinePP: this.isOneLinePPTemplate(),
            declineScreenTemplate: this.get('payModel')?.get('declineScreenTemplate') ?? null,
            isEmptyFormAfterDecline,
        }));

        var payModel = this.get('payModel'),
            previousPayModel = this.previous('payModel'),
            paymentSystems = this.get('paymentSystems');

        if (previousPayModel) {
            this.stopListening(previousPayModel);
            if (previousPayModel.get('changingPaymentDetails') && previousPayModel.get('method') !== payModel.get('method')) {
                // Reset changing payment details for sepa mandate form
                previousPayModel.unset('changingPaymentDetails');
                this.get('altMethodsScenario')[previousPayModel.get('method')] = PaymentScenario.ONECLICK;
            }
        }

        if (payModel) {
            this.listenPayModel(payModel);
        }

        if (this.isDefaultCardPaymentType()) {
            payModel?.set({
                cardTypeRules: this.get('cardTypeRules'),
                processingFeeBannerData,
            });

            if (paymentSystems && !this.get('isCardFormVisible')) {
                payModel?.set('currentBinEntity', {
                    bin: paymentSystems.card.bin,
                    financialServicePlatform: paymentSystems.card.financialServicePlatform,
                    debitType: paymentSystems.card.debitType,
                    cardTypeLogo: paymentSystems.card.cardTypeLogo,
                });
                payModel?.setBinEntity(payModel?.get('currentBinEntity'));
            } else {
                payModel?.set('currentBinEntity', {
                    bin,
                    debitType,
                    financialServicePlatform,
                    cardTypeLogo,
                });
            }
        }

        /** Need to save valid card payment scenario */
        this.fillCardScenario();

        const isPaymentPreviousRoute = isPaymentUrl(routeVar().previous);

        if (!isPaymentPreviousRoute) {
            this.successOrdersModel.clearStoredOrders(this.get('via'));
        }
    },

    listenPayModel: function(payModel) {
        this.listenTo(payModel, 'all', this.__onPayModelAll);
    },

    /**
     * @public
     * @return {boolean}
     */
    isAccountAndBank() {
        return this.get(EXTRA_TAB_NAME) === ACCOUNT_AND_BANK;
    },

    /**
     * @private
     * @param {String} Event name
     */
    __onPayModelAll: function (event) {
        // Proxy this events from payModel to self.
        if (
            _.includes([
                'pay:complete',
                'pay:fail',
                'pay:success',
                'pay:cancel',
                'pay:startExternalPayment',
            ], event))
        {
            this.trigger.apply(this, arguments);
        }
    },

    /**
     * @public
     * @param url {string}
     * @return {string}
     */
    prepareRedirectUrl: function (url) {
        if (!url) {
            return defaultController();
        }

        if (_.isObject(url)) {
            url = url[0];
        }

        const upgradeUrl = URI(url);
        const viaProfileId = this.get('viaProfileId');

        upgradeUrl.setSearch('via', this.getCurrentPaymentStage());

        if (!upgradeUrl.hasSearch('returnPath') && !_.isEmpty(this.getReturnPath())) {
            upgradeUrl.addSearch('returnPath', this.getReturnPath());
        }

        if (!upgradeUrl.hasSearch('successReturnPath') && !_.isEmpty(this.getSuccessReturnPath())) {
            upgradeUrl.addSearch('successReturnPath', this.getSuccessReturnPath());
        }

        if (viaProfileId) {
            upgradeUrl.addSearch('viaProfileId', viaProfileId);
        }

        return upgradeUrl.resource();
    },

    prepareReturnPath: function (param) {
        const url = URI(window.location);

        let path = '';

        if (url.hasSearch(param)) {
            path = url.query(true)[param];
        }

        if (this.get(param)) {
            path = this.get(param);
        }

        return decodeURIComponent(path);
    },

    /**
     * successReturnPath stored in decoded format @see URI
     * @public
     * @returns {string}
     */
    getSuccessReturnPath: function () {
        return this.prepareReturnPath('successReturnPath');
    },

    /**
     * returnPath stored in decoded format @see URI
     * @public
     * @returns {string}
     */
    getReturnPath: function () {
        return this.prepareReturnPath('returnPath');
    },

    /**
     * get array of logos from response object
     * @return {Array}
     * @private
     */
    getSecurityLogos() {
        const securityLogos = this.get('securityLogos');
        const logosForOriginalMethod = securityLogos[this.methodsCollection.getActiveOriginalMethod()];

        let logosForMethod = securityLogos[this.methodsCollection.getActiveMethod()] || [];

        // For some clones of card method we must show logos for original method if they exist!
        if (this.isCardPaymentType() && !_.isEmpty(logosForOriginalMethod)) {
            logosForMethod = logosForOriginalMethod;
        }

        return _.map(logosForMethod, (logo) => {
            return logo.toLowerCase();
        });
    },

    /**
     * @public
     */
    resetUserSelections: function() {
        delete this._tempActivePackageId;
        this.set('selectedAdditionalPackages', null);
        this.unset('user');
        this.unset('viaProfileId');
        this.setDefaultMethodTab();
    },

    /**
     * @public
     */
    resetSelectedAdditionalPackages() {
        this.set('selectedAdditionalPackages', null);
    },

    /**
     * Set default method as active
     * @private
     */
    setDefaultMethodTab: function (skipSetFromViaMethod = false) {
        if (this.isRemarketingOfferStage() && this.get('defaultActiveTab') !== PAYMENT_METHODS.CARD) {
            logger.sendWarning(`'defaultActiveTab: ${this.get('defaultActiveTab')}' is unacceptable for posttrans`);
            this.set('defaultActiveTab', PAYMENT_METHODS.CARD);
        }

        const viaMethod = this.get('viaMethod');

        const methodWithTabs = this.canShowTabForPaymentMethod({
            method: viaMethod,
            altMethodsScenario: this.get('altMethodsScenario')
        });

        /**
         * Set default method from url as it exist, or from server field 'defaultActiveTab'
         * Exclude  WALLET_METHODS. @see paypal method
         */
        const defaultMethod = (
            !skipSetFromViaMethod &&
            viaMethod &&
            methodWithTabs &&
            this.isPaymentMethodExists(viaMethod)
        ) ? viaMethod : this.get('defaultActiveTab');

        this.methodsCollection.setActive(defaultMethod);
    },

    /**
     * @public
     */
    processCollection: function() {
        var resultPackages = _.reduce(this.get('packages'), function (memo, packages) {
            memo.push.apply(memo, _.values(packages));
            return memo;
        }, []);

        /**
         * We defer update collection after response received,
         * and change packages use collection.set function with {parse: true, add: true, remove: true, merge: true} options
         */
        this.collection.set(resultPackages, {parse: true, add: true, remove: true, merge: true});

        if (this._tempActivePackageId) {
            // Need check when packages are existed
            if (!this.__isCorrectPackageId(this._tempActivePackageId)) {
                this._tempActivePackageId = this.__createPackageIdString(this._tempActivePackageId);
            }

            this.setActivePackage(this._tempActivePackageId);
            delete this._tempActivePackageId;
        }
    },

    /**
     * @summary Check packageId has correct format
     * @param  {string} packageId
     * @returns {boolean}
     * @private
     */
    __isCorrectPackageId(packageId) {
        return packageId.indexOf(this.PACKAGE_ID_DELIMITER) >= 0;
    },

    /**
     * @important Use when methodTab prop is available
     * @param  {string} packageId
     * @returns {string}
     * @private
     */
    __createPackageIdString(packageId) {
        return packageId + this.PACKAGE_ID_DELIMITER + this.methodsCollection.getActiveMethod();
    },

    /**
     * @return {object}
     * @public
     */
    getSelectedPackage: function() {
        return this.collection.where({
            is_active: true,
            originalMethod: this.methodsCollection.getActiveOriginalMethod(),
        }, true);
    },

    /**
     * @returns {object|null}
     * @public
     */
    getSelectedPackageId: function() {
        return this.getSelectedPackage() ? this.getSelectedPackage().get('package').id : null;
    },

    getMethodPackages: function (methods) {
        const packages = this.get('packages');
        return packages && _.pick(packages, _.isArray(methods) ? methods : [methods]);
    },

    /**
     * Is exist provided or current method
     * @param method {string}
     * @returns {boolean}
     */
    isPaymentMethodExists: function(method = '') {
        method = method ? method : this.methodsCollection.getActiveMethod();

        return !_.isUndefined(method) && !_.isEmpty(this.getMethodPackages(method));
    },

    /**
     *
     * @param {string} method
     * @param {String} packageId
     * @param {Boolean} withHidden - show hidden packages with specific footer (s.u. fee package)
     * @return {array} packages
     * @public
     */
    getAllAdditionalPackages(method, packageId, withHidden = false) {
        let additionalPackagesList = this.__getAdditionalPackages(method, packageId);

        if (!withHidden) {
            additionalPackagesList = filter(
                additionalPackagesList,
                additionalPackage => additionalPackage.packageType !== FEE_PACKAGE_TYPE
            );
        }

        return additionalPackagesList;
    },

    /**
     * Get next step url by action for multiple pages
     * @param {string} action
     * @return {string}
     */
    getNextStepUrl: function(action) {
        const selectedPackage = this.getSelectedPackage();
        const activeMethod = this.methodsCollection.getActiveMethod();
        const via = this.isDeclinedPay() ? (this.get('prevVia') || this.get('via')) : this.get('via');
        let url = `/pay/${action}?stockId=${selectedPackage.get('packageId')}&via=${via}&viaMethod=${activeMethod}`;

        /**
         * Need for package page to determinate valid model (feature or membership) for back button
         * @see PaymentPageModelFactory::getModelByStatus
         * @type {string}
         */
        url += `&isPaid=${!this.isMembershipStage()}`;

        if (this.get('withoutUserData')) {
            url += '&withoutUserData=true';
        }

        const returnPath = this.get('returnPath');
        if (returnPath) {
            url += `&returnPath=${encodeURIComponent(returnPath)}`;
        }

        const successReturnPath = this.get('successReturnPath');
        if (successReturnPath) {
            url += `&successReturnPath=${encodeURIComponent(successReturnPath)}`;
        }

        if (this.has('user') && this.isUserMotivation()) {
            url += `&viaProfileId=${this.get('user').id}`;
        }

        return url;
    },

    /**
     * We can get new long form fields after decline and we need to init validation
     * rules one more time.
     * @private
     */
    __handleLongFormFieldsChange: function () {
        this.set('isNeededChangeOfValidationRules', true);
    },

    /**
     * @param {string} method
     * @param {string} packageId
     * @return {array}
     * @private
     */
    __getAdditionalPackages: function (method, packageId) {
        const packagesByMethod = this.get('additionalPackages') ?
            this.get('additionalPackages')[method] :
            false;

        if (!packagesByMethod || !packageId) {
            return [];
        }

        return values(packagesByMethod[packageId]);
    },

    /**
     * Check if holiday banner is available for methodTab
     * Holiday banner is also and everytime available for different methods.
     *
     * Don't use such method before calling "initHolidayService".
     *
     * @public
     * @return {boolean}
     */
    isHolidayBannerAllowedByMethod: function () {
        return _.includes(window.app.holidayService?.getAllowedProcessorsForBanner(), this.__getMethod());
    },

    /**
     * Log syncs of PP model without via, via is required for correct work of back-end
     * @private
     */
    __validateRequestVia(model) {
        const via = model.get('via');

        try {
            if (_.isEmpty(via)) {
                throw new Error('There was request at payment model without via.');
            }

            if (via === 'undefined') {
                throw new Error('There was request at payment model with undefined via.');
            }
        } catch (e) {
            logger.captureException(e);
            console.error(e);
        }
    },

    /**
     * Check that current via is in via list, log warning otherwise
     * @private
     */
    __validateModelVia: function() {
        // No need to send error when we need to be redirected from this page
        if (!this.get('redirectUrl') && this.get('via') && _.isNull(this.getCorrectedScene())) {
            logger.sendWarning(`There is no via = "${this.get('via')}" in list.`);
        }
    },

    /**
     * Get a specific additional package by the package params
     *
     * @param {string} method
     * @param {number} packageId
     * @param {object} params
     * @return {object}
     * @public
     */
    getAdditionalPackageByParams: function (method, packageId, params) {
        return find(this.__getAdditionalPackages(method, packageId), params) || {};
    },

    /**
     * @returns {string}
     * @public
     */
    getMicrofeature: function () {
        return '';
    },

    /**
     * Is lifetime logic allowed flag
     * @public
     * @return {Boolean}
     */
    isLifetimeTemplate() {
        return getPaymentTemplate(this.get('templateSettings')?.template) === LIFE_TIME_OFFER;
    },

    /**
     * @public
     * @return {Boolean}
     */
    isOneLinePPTemplate() {
        return getPaymentTemplate(this.get('templateSettings')?.template) === ONE_LINE_PP;
    },

    /**
     * @public
     * @return {Boolean}
     */
    isOneStepClearTemplate() {
        return [ ONE_STEP_CLEAR, ONE_STEP_CLEAR_FEATURES, ONE_STEP_CLEAR_2X ].includes(
            getPaymentTemplate(this.get('templateSettings')?.template)
        );
    },

    /**
     * set active package in collection or preset it before collection create
     * @param {string} packageId
     * @returns {*}
     * @public
     */
    setActivePackage(packageId) {
        if (this.collection.length) {
            // Need check when packages are existed
            if (!this.__isCorrectPackageId(packageId)) {
                packageId = this.__createPackageIdString(packageId);
            }

            if (!this.collection.isPackageExist(packageId) && !this.collection.isPackageExistViaStockId(packageId)) {
                packageId = this.collection.findWhere({
                    originalMethod: this.get('defaultActiveTab'),
                    is_default: true,
                })?.get('id') || this.collection.first().get('id');
            }

            const currentMethod = this.collection.findWhere({
                id: packageId,
            }).get('originalMethod');

            this.methodsCollection.setActive(currentMethod);

            this.collection.setActive(packageId);
        } else {
            this._tempActivePackageId = packageId;
        }

        return this;
    },

    /**
     * @public
     */
    getCorrectedScene: function () {
        return getCorrectPaymentScene.call(this);
    },

    getPackageForBenefits: function () {
        return this.collection.min(function (model) {
            return model.get('package').id;
        });
    },

    /**
     * @public
     * return {boolean}
     */
    showAdditionalPackage: function () {
        return _.includes(this.PAID_FUNNEL_VIA, this.get('via')) ||
          includes(VIA_WITH_SELECTED_PACKAGE, this.get('via')) || this.isAdditionalTermsNeeded();
    },

    /**
     * Check if allowed additional packages as list (or specific package if you find by type)
     * @param {null|string} additionalPackageType
     * @return {boolean}
     */
    isAdditionalPackagesAvailable(additionalPackageType = null) {
        const selectedPackage = this.getSelectedPackage();

        if (!selectedPackage) {
            return false;
        }

        const additionalPackageList = this.getAllAdditionalPackages(
            this.methodsCollection.getActiveMethod(),
            selectedPackage.get('package').id
        );

        let isExistsPackages = !isEmpty(additionalPackageList);
        if (additionalPackageType) {
            const additionalPackage = find(additionalPackageList, {
                additionalPackageType,
            });
            isExistsPackages = !isEmpty(additionalPackage);
        }

        return Boolean(isExistsPackages && this.getFooterParts().additionalPackages);
    },

    /**
     * Crutch the parameter by which the package descriptor is transferred from the footer to the description block
     * @public
     * @returns {boolean}
     */
    isComplianceFriendlyAllowed() {
        return this.get('templateSettings')?.termsLayout === 'complianceFriendly' &&
            isPaymentUrl(location.pathname);
    },

    /**
     * @returns {string}
     * @protected
     */
    _getFormAction: function() {
        return '';
    },

    /**
     * Retrive current payment stage (type)
     * Can be 'membership', 'features', etc
     * @returns {string}
     */
    getCurrentPaymentStage: function() {
        return this.actionId;
    },

    /**
     * @public
     * @return {boolean}
     */
    isMembershipStage() {
        return this.getCurrentPaymentStage() === PAYMENT_ACTIONS.MEMBERSHIP;
    },

    /**
     * @public
     * @return {boolean}
     */
    isMiniMembershipStage() {
        return this.getCurrentPaymentStage() === PAYMENT_ACTIONS.MINI_MEMBERSHIP;
    },

    /**
     * @public
     * @return {boolean}
     */
    isFeatureStage() {
        return this.getCurrentPaymentStage() === PAYMENT_ACTIONS.FEATURES;
    },

    /**
     * @public
     * @return {boolean}
     */
    isRemarketingOfferStage() {
        return this.getCurrentPaymentStage() === PAYMENT_ACTIONS.REMARKETING_OFFER;
    },

    /**
     * @public
     * Refetch model from server
     */
    refetch: function() {
        this.set('ready', false);

        this.run({
            redirect: function () {
                return false;
            }
        });
    },

    applyFetch: function() {
        if (window.PP_RESP ) {
            const applyAcceleration = () => {
                /**
                 * If paid user gets to 'pay/packages' - should refetch data.
                 * Because getPayRequestAccelerationScript knows nothing about user payment status
                 * and always prefetches data for 'membership' action.
                 */
                if (window.PP_RESP.resp?.data && this.actionId === window.PP_RESP.action) {
                    this.set(this.parse(window.PP_RESP.resp.data));
                    this.trigger('sync');
                } else {
                    // fallback to model fetch if there is no prefethed data for some reason
                    this.fetch.apply(this, arguments);
                }

                delete window.PP_RESP;
            };

            if (window.PP_RESP.loading) {
                // For cases when /pay request takes more time, then app initialization time
                const waitInterval = setInterval(() => {
                    if (!window.PP_RESP.loading) {
                        applyAcceleration();
                        clearInterval(waitInterval);
                    }
                }, 50);

                return;
            }

            applyAcceleration();
        } else {
            this.fetch.apply(this, arguments);
        }
    },

    /**
     * Abstract payment method type
     *  for checking if current method is default card method
     * @return {boolean}
     */
    isDefaultCardPaymentType() {
        return this.getMethodsCollection().getActiveMethod() === PAYMENT_METHODS.CARD;
    },

    /**
     * @return {boolean}
     * @public
     */
    isNovalNetSepa() {
        return this.getMethodsCollection().getActiveMethod() === PAYMENT_METHODS.NOVAL_NET_SEPA;
    },

    isCCBillOnDeclineActive() {
        return PAYMENT_METHODS.CCBILL in this.get('packages');
    },

    isAltMethodWithSecondStep() {
        const isAltWithSecondStep = WITH_SECOND_STEP_ALT_METHODS
            .includes(this.getMethodsCollection().getActiveMethod());

        const isAltOneClick = this.get('payModel').isAltOneClick();

        return isAltWithSecondStep && !isAltOneClick;
    },

    /**
     * @return {boolean}
     */
    isAllowedBicList() {
        const method = this.getMethodsCollection().getActiveMethod();

        return [ PAYMENT_METHODS.TRUST_PAY_IDEAL, PAYMENT_METHODS.NUVEI_IDEAL ].includes(method);
    },

    /**
     * Abstract payment method type
     *  for different alt methods
     *  to run as card method type
     * @return {boolean}
     */
    isCardPaymentType() {
        return isCardPaymentType(this.__getMethod());
    },

    /**
     * Check if discount for mastercard available
     * @public
     * @returns {boolean}
     */
    isMasterCardDiscountAvailable() {
        return this.get('showMastercardDiscount') &&
          this.isDefaultCardPaymentType();
    },

    __isMethodsWithDescriptor() {
        return this.__inMethods([
            PAYMENT_METHODS.CARD,
        ]);
    },

    /**
     * @public
     * @return {boolean}
     */
    discountBannerAllowed() {
        return this.get('discount') && !this.__inMethods([
            'paygarden',
        ]) &&  this.get('motivationTemplate') === EXTRA_DISCOUNT;
    },

    /**
     * @public
     * @return {Promise<boolean>}
     */
    isWalletMethodsForAltAvailable: async function () {
        return !this.get('payModel').isAltOneClick();
    },

    /**
     * Font increase on PP for 50+
     * @return {Promise<boolean|*>}
     */
    async adultContentAvailable() {
        const { data } = await getClientInstance().query({ query: ADULT_CONTENT_AVAILABLE_QUERY });

        return Boolean(getCookie(ADULT_BIG_FONT_SIZE)) || data?.userFeatures?.isAdultContent;
    },

    /**
     * @param {Array} methods
     * @return {boolean}
     * @private
     */
    __inMethods(methods) {
        return includes(methods, this.__getMethod());
    },

    /**
     * @return {string || null}
     * @private
     */
    __getMethod() {
        return this.methodsCollection.getActiveMethod();
    },

    /**
     * Wallet allowed methods list getter
     * @return {Promise<[]>}
     */
    async getWalletAllowedMethods() {
        if ((!this.isCardPaymentType() && !await this.isWalletMethodsForAltAvailable()) ||
          (this.isCardPaymentType() && !this.get('isCardFormVisible') && !this.get('cardWallet'))) {
            return [];
        }

        return getWalletAllowedMethods({
            altMethodsSettings: this.get('altMethodsSettings'),
            packages: this.get('packages'),
            withPaypal: this.isCardPaymentType() && !isMicroFeaturesUrl(window.location.pathname),
            activePackage: this.getSelectedPackage(),
        });
    },

    /**
     * @protected
     */
    _onActivePackageChange: function () {
        // Reset all bins because for different cards we can have
        // different banned bins
        if (this.isCardPaymentType()) {
            this.get('payModel').resetBannedBins();
        }

        this.initSelectedAdditionalPackages(true);
    },

    needToHideDescriptor: function () {
        return this.get('hideDescriptor') || !this.__isMethodsWithDescriptor();
    },

    /**
     * @public
     * @returns {String}
     */
    getPrevVia() {
        const via = this.get('via');

        if (DECLINE_VIA.includes(via)) {
            return this.get('prevVia');
        }

        return via;
    },

    /**
     * return footer data
     */
    getFooterParts() {
        const footer = this.get('footer') || {};

        return footer.blockDataMap || {};
    },

    /**
     * @public
     * @return {string}
     */
    getPayButtonText: function (allowedWalletMethods) {
        if (this.get('buySecurelyPayButton')) {
            return yiiT.t('paymentPage', 'button.buy_securely');
        }
        // @TODO: After rollout to transfer in cfg/payment/page/payButtonText.yaml
        if (this.get('buyHorizontalPackagePayButton')) {
            return yiiT.t('paymentPage', 'text.pay_now');
        }

        const isPaypalOneClick = this.isPayPalOneClick() && !this.get('isCardFormVisible');

        const payButtonText = this.get('payButtonText');

        const activeOriginalMethod = this.methodsCollection.getActiveOriginalMethod();

        const paymentMethodButtonText = payButtonText?.paymentMethodButtonText;

        if (
            !this.isCardPaymentType() &&
            paymentMethodButtonText?.[activeOriginalMethod]?.defaultButtonText
        ) {
            return getPageButtonTranslate(paymentMethodButtonText[activeOriginalMethod].defaultButtonText);
        }

        if (!this.isCardPaymentType() && this.isWalletMethodsForAltAvailable()) {
            return getPayButtonText(payButtonText, []);
        }

        if (this.isOneLinePPTemplate()) {
            const processingFeeData = this.getFeeDataForTrail();
            let packagePrice = this.getActivePackagePrice();

            if (processingFeeData) {
                const { amount } = processingFeeData;
                packagePrice = amount;
            }

            return getPayButtonText(payButtonText, [], {
                '{price}': packagePrice,
            });
        }


        if (isPaypalOneClick) {
            const paypalButtonTextKey =
              payButtonText?.paymentMethodButtonText[WALLET_METHODS.PAY_PAL_V2]?.oneClickButtonText;

            if (paypalButtonTextKey) {
                return getPageButtonTranslate(paypalButtonTextKey);
            }
        }

        return getPayButtonText(payButtonText, allowedWalletMethods);
    },

    /**
     * @public
     * @return {string}
     */
    getFirstStepButtonText: function (allowedWalletMethods) {
        const defaultText = yiiT.t('paymentPage', 'text.continue');

        if (!this.isCardPaymentType() && this.isWalletMethodsForAltAvailable()) {
            return defaultText;
        }
        const {prePayButtonText} = getPayButtonTextSettings(this.get('payButtonText'), allowedWalletMethods);

        if (prePayButtonText) {
            return getPageButtonTranslate(prePayButtonText);
        }

        return defaultText;
    },

    /**
     * @public
     * @returns {String|null}
     */
    getFormattedPhonePrefix: function () {
        const phonePrefixExample = this.get('phonePrefixExample');

        return phonePrefixExample ? `+${phonePrefixExample.full_prefix}` : null;
    },

    getActivePackagePrice: function () {
        const selectedPackage = this.getSelectedPackage();

        if (!selectedPackage) {
            return '';
        }

        const { amount, currency: {
            literal: { prefix, suffix },
        }} = selectedPackage.get('price');

        return getPriceWithCurrency(amount, { suffix, prefix });
    },

    /**
     * @return {boolean}
     * @public
     */
    isExtraDay() {
        return includes(EXTRA_DAY_VIAS, this.get('via'));
    },

    /**
     * @public
     * @return {boolean}
     */
    isAdminExtraVia() {
        return ADMIN_EXTRA_FEATURE_VIA === this.get('via');
    },

    /**
     * @public
     * @return {boolean}
     */
    isAdminReadVias() {
        return ADMIN_READ_VIAS.includes(this.get('via'));
    },

    /**
     * @public
     * @return {boolean}
     */
    isAddActionVias() {
        return ADD_ACTION_VIAS.includes(this.get('via'));
    },

    /**
     * @return {boolean}
     * @public
     */
    isPayAttemptPackageExists: function () {
        const defaultPackageId = this.getSelectedPackage().get('packageId') || this.getSelectedPackage().get('id');

        if (this.get('oneStepPageAllowed')) {
            return Boolean(defaultPackageId);
        }

        return window.location.search.indexOf(defaultPackageId) !== -1;
    },

    /**
     * @public
     * @return {boolean}
     */
    isCardHolderAllowed() {
        if (this.isOneClickWithCvv()) {
            return true;
        }

        return some(this.getLongFormFields(), {
            longFormField: CARD_HOLDER,
        });
    },

    /**
     * @public
     * @return {object}
     */
    getLongFormFields() {
        const longFormFields = this.get('longFormFields') || {};

        return longFormFields.longFormFields;
    },

    /**
     * @public
     * @return {array}
     */
    getAnonymousAutoFillFields() {
        const longFormFields = this.get('longFormFields') || {};

        return longFormFields.anonymousAutoFillFields;
    },

    /**
     * @private
     * @return {boolean}
     */
    isNeoBankDebitType() {
        return [ DEBIT_TYPES.DEBIT_NEOBANK, DEBIT_TYPES.CREDIT_NEOBANK ]
            .includes(this.get('payModel')?.get('currentBinEntity')?.debitType);
    },

    /**
     * Get fee data for trail package
     * @see PaymentPageProcessingFeeBannerView.js
     * @public
     * @return {Object|Null}
     */
    getFeeDataForTrail() {
        if (!this.isMembershipStage() && !this.isMiniMembershipStage()) {
            return null;
        }

        const selectedPackage = this.getSelectedPackage();
        const processingFeeBannerData = this.get('payModel')?.get('processingFeeBannerData');

        if (
            !this.isDefaultCardPaymentType() ||
            !selectedPackage ||
            !this.isNeoBankDebitType() ||
            !selectedPackage.get('package').packageTypes.includes(PackageTypes.TRIAL) ||
            !processingFeeBannerData
        ) {
            return null;
        }

        const {currencyLiteral: { prefix, suffix }, moneyData: { amount: fee }} = processingFeeBannerData;
        const amount = calculateTotalAmount(fee, selectedPackage.getPrice().current.amount);

        return {
            fee: getPriceWithCurrency(fee, { suffix, prefix }),
            amount: getPriceWithCurrency(amount, { suffix, prefix }),
            financialServicePlatform: this.get('payModel').get('currentBinEntity').financialServicePlatform || ''
        };
    },

    /**
     * @return {Boolean}
     */
    isTrialMembershipSelectedPackage() {
        const packageTypes = this.getSelectedPackage()?.get('package')?.packageTypes || [];
        return packageTypes.includes(PackageTypes.TRIAL) && packageTypes.includes(PackageTypes.MEMBERSHIP);
    },

    /**
     * @public
     * @return {boolean}
     */
    isNeedShowBankSelect() {
        const method = this.methodsCollection.getActiveMethod();
        const paymentMethods = result(this.get('altMethodsSettings'), `${method}.paymentMethods`, []);

        return paymentMethods && paymentMethods.length;
    },

    /**
     * @public
     * @returns {Object}
     */
    getCurrentMethodSettings: function () {
        return result(this.get('altMethodsSettings'), this.methodsCollection.getActiveMethod(), {});
    },

    /**
     * Data for track pay click
     * @return {{isFormHidden: (boolean), isOneClickShown: (boolean)}}
     */
    getPayTrackData(viewDate, isWalletPayAction) {
        const payModel = this.get('payModel');
        const hasPayModelErrors = payModel?.hasErrors();
        const buttonStatus = isWalletPayAction ? this.get('isWalletButtonDisabled') : this.get('isButtonDisabled');

        const sameProps = {
            action: this.actionId,
            paymentForm: payModel?.getFormName(),
            timeInterval: viewDate ? Math.floor((Date.now() - viewDate) / 1000) : 0,
        };

        if (!this.isCardPaymentType()) {
            const method = this.__getMethod();
            const scenario = this.get('altMethodsScenario')[method];
            const isOneClickScenario = PaymentScenario.ONECLICK === scenario;

            return {
                ...sameProps,
                isOneClickShown: isOneClickScenario,
                isFormHidden: isOneClickScenario,
                isFrontendValidationPassed: isOneClickScenario ? true : !hasPayModelErrors,
                isButtonActive: buttonStatus ? false : !this.getButtonLockCondition(),
            };
        }

        const isCardFormVisible = this.get('isCardFormVisible');
        const isPaypalOneClick = this.isPayPalOneClick();
        const isShowOneClick = this.get('isShowOneClick');

        return {
            ...sameProps,
            isOneClickShown: isShowOneClick || isPaypalOneClick,
            isFormHidden: !isCardFormVisible,
            isFrontendValidationPassed: isCardFormVisible ? !hasPayModelErrors : true,
            isButtonActive: !this.isCardPayButtonLocked() && !buttonStatus,
        };
    },
}), {
    /**
     * @static
     * @public
     * @param {string} via
     * @returns {boolean}
     */
    isViaWithSelectedPackage(via) {
        return includes(VIA_WITH_SELECTED_PACKAGE, via);
    },
});

export default PaymentPageModel;
